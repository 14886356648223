import { editStation } from "./editStation";

export function displayStations(stations: any) {
  return Array.isArray(stations)
    ? stations.map((station, index) => {
        return (
          <tr
            className="stay text-center bg-gray-100 border-b border-white"
            onClick={() => editStation(station)}
            key={"station" + index}
          >
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.station_id}>
              {station.station_id}
            </td>
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.country}>
              {station.country}
            </td>
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.station_name}>
              {station.station_name}
            </td>
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.measurement_variable}>
              {station.measurement_variable}
            </td>
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.district}>
              {station.district}
            </td>
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.region}>
              {station.region}
            </td>
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.basin}>
              {station.basin}
            </td>
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.latitude}>
              {station.latitude}
            </td>
            <td className="stay tooltip truncate border-r border-[#9CDDBE] px-2" title={station.longitude}>
              {station.longitude}
            </td>
            <td className="stay tooltip truncate px-2" title={station.agency}>{station.agency}</td>
            <td className="stay truncate px-2 tooltip" title={station.equipment_type}>{station.equipment_type}</td>
            <td className="stay truncate px-2 tooltip" title={station.equipment_status}>{station.equipment_status}</td>
            <td className="stay truncate px-2 tooltip" title={station.equipment_note}>{station.equipment_note}</td>

          </tr>
        );
      })
    : null;
}
