import axios from "axios";
import { useEffect, useRef, useState } from "react";
import phoneIcon from "../..//assets/phoneIcon.svg";
import { getMessages, sendMessage } from "../Api/APIModules";
import { female_profilePicture, male_profilePicture, url } from "../variables";
import { DisplayAllMessages } from "./displayMessages";
import { unDisplaySocketLatestMessage } from "./SocketFunctions";
import { viewProfile } from "./userFunctions/UpdateUser";
import arrowDown from "../../assets/arrowDown.svg";
import { useNavigate } from "react-router-dom";

export const socket = new WebSocket(
  "wss://waterinsights.kedra.info:8000/ws/222"
);

export default function SelectedChat(params: any) {
  const [messages, setMessages] = useState<any[]>([]);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const messageBar = document.getElementById(
    "messageBar"
  ) as HTMLTextAreaElement;
  const [send, setSend] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    socket.onmessage = (event) => {
      let temp = JSON.parse(event.data);
      if (window.location.href === url + "wischat/" + temp.tel_number) {
        setMessages((messages) => [...messages, temp]);
      }
    };

    socket.onerror = (event) => {
      console.log("WebSocket error received: ", event);
    };
  }, [window.location.href]);


  useEffect(() => {
    setMessages([]);
  }, [window.location.href]);
  const handleSend = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (send.match(/^ *$/)) return;
    let messageObject = {
      message: send,
      read: true,
      sender: "WIS",
      source: "App",
      tel_number: params.user.telphone_number,
      time_stamp: Date.now(),
    };
    setMessages((messages) => [...messages, messageObject]);
    setSend("");
    sendMessage(params.user.telphone_number, "WIS", send);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    unDisplaySocketLatestMessage(params.user.telphone_number);
    getMessages(params.user.telphone_number, cancelToken, setMessages);
    return () => {
      cancelToken.cancel();
    };
  }, [window.location.href]);


  console.log(messages)
  useEffect(() => {
    scrollRef.current!.scrollIntoView({ block: "end" });
  }, [messages]);

  useEffect(() => {
    let test = document.getElementById("test") as HTMLTextAreaElement;

    test.style.width = messageBar?.offsetWidth.toString() + "px";
    test.value = messageBar?.value;
    switch (test.scrollHeight) {
      case 20:
        document.getElementById("messageBar")?.setAttribute("rows", "1");
        break;
      case 40:
        document.getElementById("messageBar")?.setAttribute("rows", "2");
        break;
      case 60:
        document.getElementById("messageBar")?.setAttribute("rows", "3");
        break;
      case 80:
        document.getElementById("messageBar")?.setAttribute("rows", "4");
        break;
      case 100:
        document.getElementById("messageBar")?.setAttribute("rows", "5");
        break;
    }
  }, [messageBar?.value]);


  return (
    <>
       <div
        className="fixed w-full h-screen bg-black bg-opacity-50 z-10 justify-center items-center hidden stay"
        id="blackScreen"
      ></div>
  
    <div className="h-screen lg:h-full flex-auto bg-white rounded-xl flex flex-col overflow-hidden text-mobile lg:text-base">
      
      <textarea id="test" className="text-sm h-0" />
      <div className="max-h-32 h-32 w-full">
        <div className="w-full px-2 xl:w-11/12 mx-auto border-b max-h-32 h-32 border-[#A3E5F6] pt-4 flex justify-between ">
          <div className="w-8/12 xl:w-3/4 flex">
            <div className="flex flex-col gap-2">
            <div className="lg:hidden" onClick={()=>navigate("/wischat")}> <img src={arrowDown} className="rotate-90"></img></div>
            <img className="bg-[#00BB59] rounded-full w-[76px] h-[76px]" src={params.user.gender === "Male"? male_profilePicture : female_profilePicture} /> 
              
            </div>
            <div className="flex-1 flex flex-col justify-center pl-1 xl:pl-4 xl:pt-6">
              <p className="font-medium">
                {" "}
                {params.user.first_name + " " + params.user.last_name}{" "}
              </p>
              <p className="text-[#00ABE4]">{params.user.role}</p>
              <p className="text-mobile lg:text-sm text-gray-500">
                District : {params.user.district} - Station Name: {params.user.station_name} - Station ID: {params.user.station_id}
              </p>
            </div>
          </div>
          <div className="w-4/12 xl:w-1/4 flex flex-col items-end justify-between">
            
            <div
              className="bg-[#00BB59] w-full text-center py-2 text-white font-bold rounded-md"
              onClick={() => viewProfile(params.user, params.stations)}
            >
              View Profile
            </div>
            <div className="flex items-center xl:gap-4">
              <img
                src={phoneIcon}
                className="xl:w-[24px] xl:h-[24px] lg:w-[18px] lg:h-[18px] h-2 w-2"
              ></img>
              <p className="xl:text-xl text-mobile lg:text-base">
                {params.user.telphone_number}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-auto overflow-hidden ">
        <div
          className="w-full h-full mx-auto overflow-y-scroll"
          id="scrollContainer"
        >
          <div className="grid grid-cols-12 gap-y-2" ref={scrollRef}>
            {messages.length !== 0 ? DisplayAllMessages(messages, params.user.gender === "Male"? male_profilePicture : female_profilePicture) : <div className="col-start-6 row-span-full mt-24">  <div className="loader "></div></div>}
          </div>
        </div>
      </div>

      <form
        className="flex flex-row items-center px-2 bg-gray-300 w-full"
        onSubmit={handleSend}
      >
        <div className="flex flex-row items-center w-full rounded-3xl px-5">
          <div className="w-full">
            <textarea
              className=" w-full focus:outline-none text-sm flex items-center resize-none bg-gray-300"
              placeholder="Type your message...."
              id="messageBar"
              autoComplete="off"
              value={send}
              onChange={(e) => setSend(e.target.value)}
            />
          </div>
          <div className="flex flex-row"></div>
        </div>
        <div className="ml-6">
          <button
            type="submit"
            className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-300 hover:bg-gray-300 text-sky-500"
          >
            <svg
              className="w-5 h-5 transform rotate-90 -mr-px"
              fill="none"
              stroke="#5AC590"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              ></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
    </>
  );
}
