import { useEffect, useState } from "react";
import ChatApp from "./ChatApp";
import SelectedChat from "./components/chat/SelectedChat";

export default function SelectedChatApp(params:any){

  const [windowSizeBolean, setSizeBolean] = useState(window.innerWidth >= 1024);
  {
    /*
   Listen to windows size change
   set window size bolean for responsive purposes
  */
  }

  useEffect(() => {
    window.addEventListener("resize", function handleResize() {
      setSizeBolean(this.window.innerWidth >= 1024);
    });
  }, []);

    return(
        <>
            {windowSizeBolean ? <ChatApp users={params.users} stations={params.stations} user={params.user} /> : <SelectedChat user = {params.user} stations={params.stations}/> }
        </>
    )
}