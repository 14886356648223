import { deleteStation, updateStation } from "../Api/APIModules";
import { confirmDelete } from "../chat/misc/ConfirmDelete";
import { blackScreen, removeBlackScreen } from "../chat/misc/userFunction";
import { viewReaders } from "./viewReaders";

function displayReadersStations(readers:any){
  let string = "";


  const removeNulls = readers.filter((reader:any) => reader !== null);

  const filteredReaders = removeNulls.filter(
    (reader:any, index:any, self:any) =>
      index === self.findIndex((r:any) => r.first_name === reader.first_name && r.last_name === reader.last_name)
  );



  for(let i = 0;  i < filteredReaders.length ;i++){
    if(filteredReaders[i] === null) break
    string = string + `   
    <div class="flex-shrink-0 max-w-64 mx-auto h-24 pt-2 pointer-events-none pr-2" key={"readersInStationArrayElement-"+index}>

              <div class=" pl-2 flex  h-24 items-center justify-center">

                <div class=" flex flex-auto h-12 p-2 px-0 items-center pointer-events-none relative  truncate">
                  <div class=" flex items-center pointer-events-none w-full truncate ">
                    <div class=" pl-2 pointer-events-none flex flex-col truncate">
                      <span class=" pointer-events-none  w-full flex truncate text-center">
                        ${filteredReaders[i].first_name + " "+ filteredReaders[i].last_name}
                      </span>
                      <p class=" text-slate-500 text-center">
                        ${filteredReaders[i].role}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
    
    
    </div> 
    `
  }
  return(string)
}

export function editStation(station : any) {

    blackScreen();
    const parentDiv = document.getElementById("blackScreen");
    const tempDiv = document.createElement("div");
    tempDiv.classList.add("w-full","lg:w-1/2")
    tempDiv.innerHTML = `
    <div class="h-screen w-full lg:h-3/4 bg-white stay lg:rounded-xl overflow-y-auto pb-24 lg:pb-8" >
    <div class="flex flex-col w-full pt-5 justify-center items-center stay">
    <div class="flex justify-end w-full text-black pr-2 stay">
      <button class="text-2xl hover:bg-gray-100 rounded-full h-9 w-9"
      id="closeButton"
      >
        X
      </button>
    </div>

    <form class="w-full px-5 flex flex-wrap justify-center stay" id="editStationForm" autoComplete="off">
    <div class="lg:w-1/2 w-full px-2 stay">

    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Station ID:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="stationID"
          value="${station.station_id}"
          
        />
      </div>
    </div>
    </div>



    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
    <div class="stay text-gray-400 pl-2 pt-2 text-sm">Country:</div>
    <div class="stay">
      <input
        class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
        type="text"
        id="country"
        value="${station.country}"
        
      />
    </div>
  </div>
  </div>


    
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
    <div class="stay text-gray-400 pl-2 pt-2 text-sm">Station Name:</div>
    <div class="stay">
      <input
        class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
        type="text"
        id="stationName"
        value="${station.station_name}"
        
      />
    </div>
  </div>
  </div>
   
  <div class="lg:w-1/2 w-full px-2 stay">
  <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
    <div class="stay text-gray-400 pl-2 pt-2 text-sm">Variable:</div>
    <div class="stay">
      <select
        class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
        id="variable"
        
      />
      <option value=""> Select Variable </option>
      <option value="Rainfall" ${station.measurement_variable === "Rainfall" ? "selected" : null}> Rainfall </option>
      <option value="River level" ${station.measurement_variable  === "River level" ? "selected" : null}> River level </option>
      </select>
    </div>
  </div>
  </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">District:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="district"
          value="${station.district}"
          required
        />
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Region:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="region"
          value="${station.region}"
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Basin:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="basin"
          value="${station.basin}"
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Lat:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="number"
          id="lat"
          value="${station.latitude}"
          step="0.000001"
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Long:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="number"
          id="long"
          value="${station.longitude}"
          step="0.000001"
        />
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Agency:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="agency"
          value="${station.agency}"
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Equipment Type:</div>
      <div class="stay">
        <select
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
          id="equipmentType"
          
        />
        <option value=""> Select type </option>
        <option value="Rainfall gauge" ${station.equipment_type === "Rainfall gauge" ? "selected" : null}> Rainfall gauge </option>
        <option value="River gauge" ${station.equipment_type === "River gauge" ? "selected" : null}> River gauge </option>
        </select>
      </div>
    </div>
    </div>
  
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Equipment Status:</div>
      <div class="stay">
        <select
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
          id="equipmentStatus"
          
        />
        <option value=""> Select Status </option>
        <option value="Non-functioning" ${station.equipment_status === "Non-functioning" ? "selected" : null}> Non-functioning </option>
        <option value="Functioning, needs repair" ${station.equipment_status === "Functioning, needs repair" ? "selected" : null}> Functioning, needs repair </option>
        <option value="Functioning" ${station.equipment_status === "Functioning" ? "selected" : null}> Functioning </option>
        </select>
      </div>
    </div>
    </div>
  


    <div class=" w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Equipment note:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="equipmentNote"
          value="${station.equipment_note}"
          
        />
      </div>
    </div>
    </div>
<div class="w-full flex-col stay mt-4">
    <p class="w-full text-center stay"> Readers</p>
    <div class="w-full overflow-hidden">
  <div class="flex p-2 stay overflow-x-scroll flex-shrink-0">
    
    ${displayReadersStations(station.readers_details)}

      </div>
      </div>
  </div>


    <div class="w-full flex">
    <div class="w-1/2 p-1 px-2">
    <div class="stay w-full bg-white  mt-2 flex rounded-md">
      <button class="stay w-full bg-red-400 rounded-md  hover:bg-red-500 p-2" id="deleteStationButton" type="button">Delete Station</button>
    </div>
    </div>
  
    <div class="w-1/2 p-1 px-2">
    <div class="stay w-full bg-white border border-black mt-2 flex rounded-md">
    <button class="stay w-full hover:bg-gray-100 rounded-md p-2" type="submit">Update Station</button>
    </div>
  </div>
  </div>
    </form>
  </div>
  </div>
      `;
    parentDiv?.appendChild(tempDiv);

    document.getElementById("readersButton")?.addEventListener('click', function handleSubmit(event){

        removeBlackScreen()
        viewReaders(station.readers)
    })

    document.getElementById("closeButton")?.addEventListener('click', function handeClick(){
        removeBlackScreen()
      } )

    document
    .getElementById("deleteStationButton")
    ?.addEventListener("click", function handeClick() {
      removeBlackScreen()
      confirmDelete(deleteStation, station.station_id);
    });

    document.getElementById("editStationForm")?.addEventListener('submit', function handleSubmit(event){
      event.preventDefault()
      const stationID = document.getElementById(
        "stationID"
      ) as HTMLInputElement;
      const stationName = document.getElementById(
        "stationName"
      ) as HTMLInputElement;
      const variable = document.getElementById("variable") as HTMLInputElement;
      const district = document.getElementById("district") as HTMLInputElement;
      const country = document.getElementById("country") as HTMLInputElement;
      const region = document.getElementById("region") as HTMLInputElement;
      const agency = document.getElementById("agency") as HTMLInputElement;
      const long = document.getElementById("long") as HTMLInputElement;
      const lat = document.getElementById("lat") as HTMLInputElement;
      const equipmentType = document.getElementById(
        "equipmentType"
      ) as HTMLInputElement;
      const equipmentStatus = document.getElementById(
        "equipmentStatus"
      ) as HTMLInputElement;
      const equipmentNote = document.getElementById(
        "equipmentNote"
      ) as HTMLInputElement;
      const basin = document.getElementById("basin") as HTMLInputElement;

      let argumentObject = {
        "station_id": stationID.value,
        "station_name": stationName.value,
        "measurement_variable": variable.value,
        "district": district.value,
        "country": country.value,
        "region": region.value,
        "basin": basin.value,
        "agency": agency.value,
        "latitude": lat.value,
        "longitude": long.value,
        "equipment_type": equipmentType.value,
        "equipment_status": equipmentStatus.value,
        "equipment_note": equipmentNote.value,
      };

      updateStation(argumentObject, station.station_id)


    })
  
  }

