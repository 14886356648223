import axios, { CancelTokenSource } from "axios";
import { getCookie } from "./Cookies";
import { APIROUTE } from "./APIRoutes";
import { url } from "../variables";
// create_station
// delete_station
// update_station
// get_all_stations

const accessToken = document.cookie.split("acessToken=")[1];

const authAxios = axios.create({
  baseURL: APIROUTE,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

export const updateContact = async (oldStation:string, argumentObject : any, telphone_number :string) => {

  try {
    await authAxios.post(
      APIROUTE +
        `update_user?telphone_number=${telphone_number.replace("+", "%2B")}`,
      argumentObject
    );
    window.location.replace(url + "wischat/" + telphone_number);
  } catch (err) {
    console.log(err);
  }
};

export const createContactBE = async (
  argumentObject:any,) => {
  try {

    await authAxios.post(`${APIROUTE}create_user`, argumentObject);
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
};

export const deleteUser = async (number: string) => {
  try {
    await authAxios.post(
      `${APIROUTE}delete_user?number=${number.replace("+", "%2B")}`
    );
    window.location.replace(url + "wischat");
  } catch (err) {
    console.log(err);
  }
};

export const sendMessage = async (
  tel_number: string,
  sender: string,
  message: string
) => {
  try {
    await authAxios.post(
      `${APIROUTE}register_message?tel_number=${tel_number.replace(
        "+",
        "%2B"
      )}&sender=${getCookie("username")}&message=${message}`
    );
  } catch (err) {
    console.log(err);
  }
};

// Country Array

export const login = async (
  e: React.FormEvent<HTMLFormElement>,
  username: string,
  password: string
) => {
  e.preventDefault();
  try {
    const response = await axios.post(
      APIROUTE + `token`,
      { username: username, password: password },
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    var timeToAdd = 1000 * 60 * 60 * 24;
    var date = new Date();
    var expiryTime = date.getTime() + timeToAdd;
    date.setTime(expiryTime);
    var utcTime = date.toUTCString();

    document.cookie =
      `acessToken=${response.data.access_token}; expires=` +
      utcTime + ";"
    document.cookie =
      `username=${response.data.full_name}; expires=` + utcTime + ";"


    document.cookie =
    `countries=${JSON.stringify(response.data.countries)}; expires=` + utcTime + ";"
  window.location.replace(url + "home");
  } catch (err) {
    console.log(err);
  }
};


// Country argument

export const getUsers = async (
  cancelToken: CancelTokenSource,
  setUsers: React.Dispatch<React.SetStateAction<never[]>>,
  selectedCountry:string,
) => {
  try {
    const data = await authAxios.get(APIROUTE + `guage_readers?country=${selectedCountry}`, {
      cancelToken: cancelToken.token,
    });
    setUsers(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};

// Country

export const getFilters = async (
  cancelToken: CancelTokenSource,
  setFilter: React.Dispatch<React.SetStateAction<never[]>>,
  params:string,
  selectedCountry:string,
) => {
  try {

    const data = await authAxios.get(APIROUTE + `get_data_center_filters?station_type=${params}&country=${selectedCountry}`, {
      cancelToken: cancelToken.token,
    });
    
    setFilter(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};




export const getStats = async (
  cancelToken: CancelTokenSource,
  setStats: React.Dispatch<React.SetStateAction<never[]>>,
  date_range:string,
  station_id:string,
  dataType : string

) => {
  try {
    const data = await authAxios.get(APIROUTE + `get_graph_data?date_range=${date_range}&station_id=${encodeURIComponent(station_id)}&reading_type=${dataType}`, {
      cancelToken: cancelToken.token,
    });
    

    setStats(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};


export const get_rainfall_graph_data = async (
  cancelToken: CancelTokenSource,
  setStats: React.Dispatch<React.SetStateAction<never[]>>,
  date_range:string,
  station_id:string,

) => {
  try {
    const data = await authAxios.get(APIROUTE + `get_rainfall_graph_data?date_range=${date_range}&station_id=${encodeURIComponent(station_id)}`, {
      cancelToken: cancelToken.token,
    });
    

    setStats(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};

export const get_temperature_graph_data = async (
  cancelToken: CancelTokenSource,
  setStats: React.Dispatch<React.SetStateAction<never[]>>,
  date_range:string,
  station_id:string,

) => {
  try {
    const data = await authAxios.get(APIROUTE + `get_temperature_graph_data?date_range=${date_range}&station_id=${encodeURIComponent(station_id)}`, {
      cancelToken: cancelToken.token,
    });
    

    setStats(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};


export const getTemperaturStats = async (
  cancelToken: CancelTokenSource,
  setStats: React.Dispatch<React.SetStateAction<never[]>>,
  date_range:string,
  station_id:string,
  dataType : string

) => {
  try {
    const data = await authAxios.get(APIROUTE + `get_temperature_graph_data?date_range=${date_range}&station_id=${encodeURIComponent(station_id)}`, {
      cancelToken: cancelToken.token,
    });
    

    setStats(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};

// Country
export const getTableData = async (
  cancelToken: CancelTokenSource,
  setUseState: React.Dispatch<React.SetStateAction<any[]>>,
  date_range:string,
  station_type:string,
  selectedCountry:string,


) => {
  try {
    const data = await authAxios.get(APIROUTE + `get_table_data?date_range=${date_range}&station_type=${station_type}&country=${selectedCountry}`, {
      cancelToken: cancelToken.token,
    });
    

    setUseState(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};

export const getMessages = async (
  number: string,
  cancelToken: CancelTokenSource,
  setMessages: React.Dispatch<React.SetStateAction<any[]>>
) => {
  try {
    let filteredNumber = number.replace("+", "%2B");
    const data = await authAxios.get(
      APIROUTE + `get_all_messages?mobile_number=${filteredNumber}`,
      { cancelToken: cancelToken.token }
    );
    setMessages(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};





// Station API functions

// Country argument
export const getStations = async (
  cancelToken: CancelTokenSource,
  setStations: React.Dispatch<React.SetStateAction<never[]>>,
  selectedCountry:string,
) => {
  try {
    const data = await authAxios.post(APIROUTE + `get_all_stations?country=${selectedCountry}`, {
      cancelToken: cancelToken.token,
    });
    setStations(data.data);
  } catch (err) {
    if (axios.isCancel(err)) {
    } else {
    }
  }
};

export const createStation = async (argumentObject:any,) => {
  try {
    await authAxios.post(`${APIROUTE}create_station`, argumentObject);
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
};

export const updateStation = async (argumentObject : any, station_id :string) => {

  try {
    await authAxios.post(
      APIROUTE +
        `update_station?station_id=${encodeURIComponent(station_id)}`,
      argumentObject
    );
    window.location.reload()
  } catch (err) {
    console.log(err);
  }
};




export const deleteStation = async (station_id: string) => {
  try {
    await authAxios.post(
      `${APIROUTE}delete_station?station_id=${encodeURIComponent(station_id)}`
    );
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
};