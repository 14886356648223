import { searchBar } from "./SearchBar";
import { NavigateFunction } from "react-router-dom";
import { female_profilePicture, male_profilePicture, url } from "../variables";
import { latestMessageTime } from "./Time";
import { isSelected } from "./misc/SelectedUser";

export function displayUsers(
  users: any[],
  searchText: string,
  navigate: NavigateFunction
) {
  if (users.length === 0) return;

  users.sort(function (x, y) {
    return y.last_message_time - x.last_message_time;
  });
  return Array.isArray(users)
    ? users.map((user, index) => {
        return (
          <div
            className="h-16 min-h-16 flex w-full"
            key={"users" + index}
            id={`sidebarUsers-${user.telphone_number}`}
            onClick={() =>
              isSelected(
                `sidebarUsers-${user.telphone_number}`,
                user.telphone_number,
                navigate
              )
            }
          >
            <div className="w-1/5 bg-[#00BB59] text-white flex items-center justify-center text-xs font-medium text-center border-b border-white">
              {user.role}
            </div>
            <div className={`w-4/5 flex justify-center ${user.is_read ? "" : "bg-green-50"}`} id={`user-`+ user.telphone_number+'-parentDiv'}>
              <div className="w-11/12 border-b border-[#A3E5F6] flex text-xs justify-between items-center">
                <div className="w-9/12 flex gap-2">
                  <img className="bg-[#00BB59] rounded-full w-[45px] h-[45px]" src={user.gender === "Male"? male_profilePicture : female_profilePicture}></img>
                  <div className="flex-1 flex-col flex justify-center truncate pr-2">
                    <p className={`truncate flex`} id={"username-"+ user.telphone_number}>
                    {searchText.match(/^ *$/) === null
                          ? searchBar(
                              user.first_name + " " + user.last_name,
                              searchText
                            )
                          : user.first_name + " " + user.last_name}
                    </p>
                    <span className="truncate block text-[#00ABE4]" id={"userText-"+ user.telphone_number}>
                      {user.last_message}
                    </span>
                  </div>
                </div>
                <div className="3/12 overflow-visible text-gray-400" id={`latestMessageTime-${user.telphone_number}`}>
                {latestMessageTime(user.last_message_time)}
                </div>
              </div>
            </div>
          </div>
        );
      })
    : null;
}
