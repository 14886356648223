import { createStation } from "../Api/APIModules";
import { blackScreen, removeBlackScreen } from "../chat/misc/userFunction";

export function addStation() {
  blackScreen();
  const parentDiv = document.getElementById("blackScreen");
  const tempDiv = document.createElement("div");
  tempDiv.classList.add("w-full", "lg:w-1/2");
  tempDiv.innerHTML = `
    <div class="h-screen w-full lg:h-3/4 bg-white stay lg:rounded-xl overflow-y-auto pb-24 lg:pb-8" >
    <div class="flex flex-col w-full pt-5 justify-center items-center stay">
    <div class="flex justify-end w-full text-black pr-2 stay">
      <button class="text-2xl hover:bg-gray-100 rounded-full h-9 w-9"
      id="closeButton"
      >
        X
      </button>
    </div>

    <form class="w-full px-5 flex flex-wrap justify-center stay" id="addStationForm" autoComplete="off">
    <div class="lg:w-1/2 w-full px-2 stay">

    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Station ID:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="stationID"
          value=""
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
    <div class="stay text-gray-400 pl-2 pt-2 text-sm">Country:</div>
    <div class="stay">
      <input
        class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
        type="text"
        id="country"
        value=""
        
      />
    </div>
  </div>
  </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
    <div class="stay text-gray-400 pl-2 pt-2 text-sm">Station Name:</div>
    <div class="stay">
      <input
        class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
        type="text"
        id="stationName"
        value=""
        
      />
    </div>
  </div>
  </div>
   
  <div class="lg:w-1/2 w-full px-2 stay">
  <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
    <div class="stay text-gray-400 pl-2 pt-2 text-sm">Variable:</div>
    <div class="stay">
      <select
        class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
        id="variable"
        
      />
      <option value=""> Select Variable </option>
      <option value="Rainfall"> Rainfall </option>
      <option value="River level"> River level </option>
      </select>
    </div>
  </div>
  </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">District:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="district"
          value=""
          required
        />
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Region:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="region"
          value=""
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Basin:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="basin"
          value=""
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Lat:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="number"
          id="lat"
          value=""
          step="0.000001"
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Long:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="number"
          id="long"
          value=""
          step="0.000001"
        />
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Agency:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="agency"
          value=""
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Equipment Type:</div>
      <div class="stay">
        <select
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
          id="equipmentType"
          
        />
        <option value=""> Select type </option>
        <option value="Rainfall gauge"> Rainfall gauge </option>
        <option value="River gauge"> River gauge </option>
        </select>
      </div>
    </div>
    </div>
  
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Equipment Status:</div>
      <div class="stay">
        <select
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
          id="equipmentStatus"
          
        />
        <option value=""> Select Status </option>
        <option value="Non-functioning"> Non-functioning </option>
        <option value="Functioning, needs repair"> Functioning, needs repair </option>
        <option value="Functioning"> Functioning </option>
        </select>
      </div>
    </div>
    </div>
  


    <div class=" w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Equipment note:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="equipmentNote"
          value=""
          
        />
      </div>
    </div>
    </div>

    <div class="stay w-3/4 bg-white border border-black mt-2 flex flex-col rounded-md hover:bg-gray-300">
      <button class="stay p-2 ">Add Station</button>
    </div>
    </form>
  </div>
  </div>
      `;
  parentDiv?.appendChild(tempDiv);

  document.getElementById("closeButton")?.addEventListener('click', function handeClick(){
    removeBlackScreen()
  } )

  document
    .getElementById("addStationForm")
    ?.addEventListener("submit", function handleSubmit(event) {
      event.preventDefault();
      const stationID = document.getElementById(
        "stationID"
      ) as HTMLInputElement;
      const stationName = document.getElementById(
        "stationName"
      ) as HTMLInputElement;
      const variable = document.getElementById("variable") as HTMLInputElement;
      const district = document.getElementById("district") as HTMLInputElement;
      const country = document.getElementById("country") as HTMLInputElement;
      const region = document.getElementById("region") as HTMLInputElement;
      const agency = document.getElementById("agency") as HTMLInputElement;
      const long = document.getElementById("long") as HTMLInputElement;
      const lat = document.getElementById("lat") as HTMLInputElement;
      const equipmentType = document.getElementById(
        "equipmentType"
      ) as HTMLInputElement;
      const equipmentStatus = document.getElementById(
        "equipmentStatus"
      ) as HTMLInputElement;
      const equipmentNote = document.getElementById(
        "equipmentNote"
      ) as HTMLInputElement;
      const basin = document.getElementById("basin") as HTMLInputElement;

      let argumentObject = {
        "station_id": stationID.value,
        "station_name": stationName.value,
        "measurement_variable": variable.value,
        "district": district.value,
        "country": country.value,
        "region": region.value,
        "basin": basin.value,
        "agency": agency.value,
        "latitude": lat.value,
        "longitude": long.value,
        "equipment_type": equipmentType.value,
        "equipment_status": equipmentStatus.value,
        "equipment_note": equipmentNote.value,
        "readers" : [],
      };

      createStation(argumentObject);
    });
}
