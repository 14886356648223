import { NavigateFunction } from "react-router-dom";

var prevSelected: string;

export function isSelected(selectedDiv: string, index: string,  navigate : NavigateFunction) {
    const selected = document.getElementById(selectedDiv);
    const unSelected = document.getElementById(prevSelected);
    if (selected === unSelected) return;
  
    selected?.classList.add("font-bold");
    unSelected?.classList.remove("font-bold");
  
    prevSelected = selectedDiv;
    navigate("/wischat/" + index);
  }