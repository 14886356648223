export function searchBar(username: string, searchText: string) {
    var splitter = new RegExp(`(${searchText.replace(/\s/g, "")})`, "i");
    let array = username.split(splitter);
    return array.map((x, index) => {
      return (
        <div
          className={
            x.toLowerCase().replace(/\s/g, "") ===
            searchText.toLowerCase().replace(/\s/g, "")
              ? "text-[#5AC590] font-medium text-md "
              : "" 
          }
          key={"text-" + index}
        >
          {" "}
          {x.replace(" ", "\u00A0")}{" "}
        </div>
      );
    });
  }