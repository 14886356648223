
export function blackScreen() {
  const blackScreen = document.getElementById("blackScreen");
  blackScreen?.classList.remove("hidden");
  blackScreen?.classList.add("flex");
}

export function changeProfilePicture(profile: string) {
  var profilePicture = document.getElementById(profile);
  if (
    profilePicture?.classList.contains(
      "bg-[url(https://i.ibb.co/3d6TKxP/New-Project-1.png)]"
    )
  ) {
    profilePicture?.classList.remove(
      "bg-[url(https://i.ibb.co/3d6TKxP/New-Project-1.png)]"
    );
    profilePicture?.classList.add(
      "bg-[url(https://i.ibb.co/Hnz0jbZ/New-Project-2.png)]"
    );
    return;
  }
  profilePicture?.classList.remove(
    "bg-[url(https://i.ibb.co/Hnz0jbZ/New-Project-2.png)]"
  );
  profilePicture?.classList.add(
    "bg-[url(https://i.ibb.co/3d6TKxP/New-Project-1.png)]"
  );
}

export function removeBlackScreen() {
  const blackScreen = document.getElementById("blackScreen");
  blackScreen?.classList.remove("flex");
  blackScreen?.classList.add("hidden");
  const parentDiv = document.getElementById("blackScreen") as HTMLDivElement;
  while (parentDiv?.firstChild) {
    parentDiv.removeChild(parentDiv.lastChild as HTMLElement);
  }
}
