import Dashboard from "../Dashboard";

export default function LoadingScreen() {
  function pageNotFound() {
    setTimeout(function () {
      if (document.getElementById("replace")) {
        document.getElementById("replace")!.innerText = "404 Page not found.";
      }
    }, 5000);

    return;
  }

  pageNotFound();

  return (
    <>
      <div className="bg-gradient-to-b from-[#00A2D8] to-[#88DFBC] w-full h-screen opacity-[0.23] fixed z-0"></div>
      <div
        className="fixed w-full h-screen bg-black bg-opacity-50 z-10 justify-center items-center hidden stay"
        id="blackScreen"
      ></div>
      <div className="h-screen w-full p-6 ">
        <div className="w-full h-full relative border-2 border-white rounded-xl p-6 bg-white bg-opacity-40 flex">
          <Dashboard />
          <div className="lg:w-9/12 xl:w-10/12 h-full flex flex-col w-full items-end justify-center">
            <div className="lg:w-4/5 xl:w-5/6 h-full flex justify-center flex-col items-center">
              <img
                src={
                  "https://static.wixstatic.com/media/a2eddc_fe73a659d6354f3db5fab23638f7041b~mv2.png/v1/fill/w_228,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Water-in-Sight-logo.png"
                }
              />
              <div>
                <p id="replace">Loading ...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
