import riverIcon from "./assets/riverIcon.svg";
import riverIconGreen from "./assets/riverIconGreen.svg";

import rainIcon from "./assets/rainIcon.svg";
import rainIconWhite from "./assets/rainIconWhite.svg";
import tempWhite from "./assets/tempWhite.svg";
import tempGreen from "./assets/tempGreen.svg";

import profilePic from "./assets/profilePic.svg";

import downloadIcon from "./assets/downloadIcon.svg";
import searchIcon from "./assets/searchIcon.svg";
import {
  MapContainer,
  Marker,
  Popup,
  ScaleControl,
  TileLayer,
  useMap,
  GeoJSON,
} from "react-leaflet";
import L, { geoJSON } from "leaflet";
import * as geojson from "geojson";
import countries from "./malawi.json";
import "leaflet/dist/leaflet.css";
import { useEffect, useImperativeHandle, useState } from "react";
import axios from "axios";
import { cookieExsist, getCookie } from "./components/Api/Cookies";
import {
  getFilters,
  getStats,
  getTableData,
  getTemperaturStats,
  get_rainfall_graph_data,
  get_temperature_graph_data,
} from "./components/Api/APIModules";
import { displayDashboardUsers } from "./displayDashboardUsers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  displayDashboardFilterRadio,
  getStationId,
} from "./DisplayDashboardFilter";
import Stations, {
  selectedCountryCenter,
  selectedCountryMap,
  selectedCountryZoom,
} from "./Stations";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  LabelList,
  Line,
  LineChart,
  Dot,
} from "recharts";
import { displayStationsDataCenter } from "./displayStationsDataCenter";
import {
  downloadCsvClimSoft,
  downloadCsvFromArrayOfObjects,
  downloadCsvFromHtmlTable,
  downloadExcelClimSoft,
  downloadExcelFromArrayOfObjects,
} from "./downloadCSV";
import { blackScreen } from "./components/chat/misc/userFunction";
import { logoutMobile } from "./logoutMobile";
import { selectedCountry } from "./components/chat/misc/SelectedCountry";
import {
  displayCountries,
  displayCountriesDropDown,
  removeCountries,
} from "./Dashboard";
import RenderCustomizedLabel from "./RenderCustomizedLabelBarChart";

export function findLocationPath(
  input: any,
  id: any,
  level: number,
  path: string[] = []
): string[] {
  let matches: string[] = [];

  for (let key in input) {
    if (typeof input[key] === "object" && level > 1) {
      let result: any = findLocationPath(input[key], id, level - 1, [
        ...path,
        key,
      ]);
      if (result !== null) {
        matches.push(...result);
      }
    } else if (key === id) {
      matches.push([...path, key].join(">"));
    }
  }

  return matches;
}

export default function Stats(params: any) {
  const data: GeoJSON.Feature = {
    type: "Feature",
    geometry: selectedCountryMap(),
    properties: {},
  };

  const icon = L.icon({
    iconUrl: "https://i.ibb.co/QJz5Fqb/marker-icon.png",
    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });

  const today = new Date();
  const january1st2023 = new Date("2023-01-01");

  // const monthAgo = new Date();
  // monthAgo.setMonth(monthAgo.getMonth() - 1);
  // monthAgo.setHours(0, 0, 0);
  // today.setHours(0, 0, 0);

  const [userFilter, setUserFilter] = useState(Object);
  const [tableData, setTableData] = useState<any[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<any[]>([]);

  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);

  const [stats, setStats] = useState(Object);
  const [readers, setReaders] = useState<any[]>([]);

  const [selectedStation, setSelectedStation] = useState("");
  const [selectedStationName, setSelectedStationName] = useState("");

  const [dataType, setDataType] = useState(
    getCookie("selectedCountry") === "Mozambique" ? "Rainfall" : "River Level"
  );

  const [dateRange, setDateRange] = useState<any>([january1st2023, today]);

  const [stationSearch, setStationSearch] = useState("");

  const [startDate, endDate] = dateRange;

  const [windowSizeBolean, setSizeBolean] = useState(window.innerWidth >= 1024);
  const [regions, setRegions] = useState<any>();
  const [districts, setDistricts] = useState<any>();
  const [basins, setBasins] = useState<any>();
  const [stations, setStations] = useState<any>();
  const [rainfallGraphData, setRainfallGraphData] = useState(Object);

  {
    /*
   Listen to windows size change
   set window size bolean for responsive purposes
  */
  }

  useEffect(() => {
    window.addEventListener("resize", function handleResize() {
      setSizeBolean(this.window.innerWidth >= 1024);
    });
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (cookieExsist("acessToken")) {
      getFilters(cancelToken, setUserFilter, dataType, selectedCountry());
    }
    return () => {
      cancelToken.cancel();
    };
  }, [dataType, getCookie("selectedCountry")]);

  useEffect(() => {
    let slicedDateRange0 =
      dateRange[0].getFullYear() +
      "-" +
      (dateRange[0].getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      dateRange[0].getDate().toString().padStart(2, "0");
    let slicedDateRange1 =
      dateRange[1] === null
        ? today.getFullYear() +
          "-" +
          (today.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          today.getDate().toString().padStart(2, "0")
        : dateRange[1].getFullYear() +
          "-" +
          (dateRange[1].getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          dateRange[1].getDate().toString().padStart(2, "0");
    const cancelToken = axios.CancelToken.source();

    if (cookieExsist("acessToken")) {
      getTableData(
        cancelToken,
        setTableData,
        slicedDateRange0 + "_" + slicedDateRange1,
        dataType,
        selectedCountry()
      );
    }

    return () => {
      cancelToken.cancel();
    };
  }, [dataType, dateRange, getCookie("selectedCountry")]);
 
  useEffect(() => {
    if (selectedStation !== "") {
      let slicedDateRange0 =
        dateRange[0].getFullYear() +
        "-" +
        (dateRange[0].getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        dateRange[0].getDate().toString().padStart(2, "0");
      let slicedDateRange1 =
        dateRange[1] === null
          ? today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0")
          : dateRange[1].getFullYear() +
            "-" +
            (dateRange[1].getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            dateRange[1].getDate().toString().padStart(2, "0");
      const cancelToken = axios.CancelToken.source();

      if (cookieExsist("acessToken")) {
        if (dataType === "Temperature") {
          get_temperature_graph_data(
            cancelToken,
            setStats,
            slicedDateRange0 + "_" + slicedDateRange1,
            selectedStation
          );
        } else if (dataType === "Rainfall") {
          get_rainfall_graph_data(
            cancelToken,
            setRainfallGraphData,
            slicedDateRange0 + "_" + slicedDateRange1,
            selectedStation
          );
        } else {
          getStats(
            cancelToken,
            setStats,
            slicedDateRange0 + "_" + slicedDateRange1,
            selectedStation,
            dataType
          );
        }
      }
      return () => {
        cancelToken.cancel();
      };
    } else {
      if (stations && userFilter) {
        let sortedStations = stations.sort((a: any, b: any) =>
          a.localeCompare(b)
        );

        let pathArr = findLocationPath(
          userFilter,
          sortedStations[0],
          4
        )[0]?.split(">");

        if (pathArr) {
          setSelectedStation(
            userFilter[pathArr[0]][pathArr[1]][pathArr[2]][pathArr[3]]
              .station_id
          );

          setSelectedStationName(pathArr[3]);
        }
      }
    }
  }, [selectedStation, dateRange, getCookie("selectedCountry"), stations]);

  useEffect(() => {
    setSelectedStation("");
    let stationsForm = document.getElementById(
      "stationsForm"
    ) as HTMLFormElement;
    let firstRadio = stationsForm.firstChild?.firstChild as HTMLInputElement;
    if (firstRadio) {
      firstRadio.checked = true;
    }
  }, [dataType]);

  // function findLocationPath(input: any, id: any, level: number) {
  //   for (let key in input) {
  //     if (typeof input[key] === "object" && level > 1) {
  //       let result: any = findLocationPath(input[key], id, level - 1);
  //       if (result !== null) {
  //         return key + ">" + result;
  //       }
  //     } else if (key === id) {
  //       return id;
  //     }
  //   }
  //   return null;
  // }

  function getNestedObjectByPath(
    obj: Record<string, any>,
    pathArr: string[]
  ): any {
    let value: any = {};

    for (let i = 0; i < pathArr.length; i++) {
      const keys = pathArr[i].split(">");

      let nestedObj = obj;
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j];

        nestedObj = nestedObj[key];

        if (nestedObj === undefined) {
          return undefined;
        }
      }
      if (Object.keys(value).length === 0) {
        value = nestedObj;
      } else {
        value = { ...value, ...nestedObj };
      }
    }

    return value;
  }

  function getNestedObjectKeys(obj: any, level: number): string[] {
    if (!obj) {
      return [];
    }
    if (level === 1) {
      return Object.keys(obj);
    }
    const nestedKeys: string[] = [];
    Object.values(obj).forEach((value) => {
      if (typeof value === "object" && value !== null) {
        const keys = getNestedObjectKeys(value, level - 1);
        nestedKeys.push(...keys);
      }
    });

    return nestedKeys;
  }

  // const [prevRegionsArr, setPrevRegionsArr] = useState<any>();
  // const [prevStationsArr, setPrevStationsArr] = useState<any>();
  // const [prevDistrtictsArr, setPrevDistrtictsArr] = useState<any>();
  // const [prevBasinsArr, setPrevBasinsArr] = useState<any>();

  useEffect(() => {
    if (Object.keys(userFilter).length !== 0) {
      setRegions(getNestedObjectKeys(userFilter, 1));
      setDistricts(getNestedObjectKeys(userFilter, 2));
      setBasins(getNestedObjectKeys(userFilter, 3));
      setStations(getNestedObjectKeys(userFilter, 4));
    }
  }, [userFilter]);

  useEffect(() => {
    if (Object.keys(userFilter).length === 0) return;
    document.getElementById("regionsForm")?.addEventListener("input", () => {
      const inputs = document.getElementsByClassName("checkbox-1");
      let tempArr: string[] = [];
      let tempArr2: string[] = [];
      let tempArr3: string[] = [];
      let ischecked = false;

      const RemoveCheck = Array.from(checkboxes).filter(
        (checkbox) =>
          !checkbox.classList.contains("exclude") &&
          !checkbox.classList.contains("checkbox-1") &&
          (checkbox as HTMLInputElement).checked
      ) as HTMLInputElement[];

      for (let i = 0; i < RemoveCheck.length; i++) {
        let remove = RemoveCheck[i] as HTMLInputElement;
        remove.checked = false;
      }

      for (let i = 0; i < inputs.length; i++) {
        let temp = inputs[i] as HTMLInputElement;
        if (temp.checked) {
          let nestedArr = getNestedObjectKeys(
            userFilter[temp.name.slice(2)],
            1
          );
          tempArr = [...tempArr, ...nestedArr];
          nestedArr = getNestedObjectKeys(userFilter[temp.name.slice(2)], 2);
          tempArr2 = [...tempArr2, ...nestedArr];
          nestedArr = getNestedObjectKeys(userFilter[temp.name.slice(2)], 3);
          tempArr3 = [...tempArr3, ...nestedArr];
          ischecked = true;
        }
      }
      if (!ischecked) {
        setDistricts(getNestedObjectKeys(userFilter, 2));
        setBasins(getNestedObjectKeys(userFilter, 3));
        setStations(getNestedObjectKeys(userFilter, 4));
        // setPrevDistrtictsArr(getNestedObjectKeys(userFilter, 2))
        // setPrevBasinsArr(getNestedObjectKeys(userFilter, 3))
        // setPrevStationsArr(getNestedObjectKeys(userFilter, 4))
      } else {
        setDistricts(tempArr);
        setBasins(tempArr2);
        setStations(tempArr3);

        // setPrevDistrtictsArr(tempArr);
        // setPrevBasinsArr(tempArr2)
        // setPrevStationsArr(tempArr3)
      }
    });

    document.getElementById("districtsForm")?.addEventListener("input", () => {
      if (Object.keys(userFilter).length === 0) return;

      const inputs = document.getElementsByClassName("checkbox-2");
      let tempArr: string[] = [];
      let tempArr2: string[] = [];
      let ischecked = false;

      const parentCheckboxes = document.getElementsByClassName("checkbox-1");

      const RemoveCheck = document.getElementsByClassName("checkbox-3");

      const FilteredRemoveCheck = Array.from(RemoveCheck).filter(
        (checkbox) => (checkbox as HTMLInputElement).checked
      ) as HTMLInputElement[];

      for (let i = 0; i < FilteredRemoveCheck.length; i++) {
        let remove = FilteredRemoveCheck[i] as HTMLInputElement;
        remove.checked = false;
      }

      for (let i = 0; i < inputs.length; i++) {
        let temp = inputs[i] as HTMLInputElement;

        if (temp.checked) {
          let pathArr = findLocationPath(userFilter, temp.name.slice(2), 2, []);
          if (parentCheckboxes.length > 0) {
            let newPathArr = [];
            for (let j = 0; j < parentCheckboxes.length; j++) {
              let parentTemp = parentCheckboxes[j] as HTMLInputElement;
              for (let n = 0; n < pathArr.length; n++) {
                if (pathArr[n].includes(parentTemp.name.slice(2))) {
                  newPathArr.push(pathArr[n]);
                } else {
                  continue;
                }
              }
            }
            pathArr = newPathArr;
          }

          let returnValue = getNestedObjectKeys(
            getNestedObjectByPath(userFilter, pathArr),
            1
          );
          tempArr = [...tempArr, ...returnValue];
          returnValue = getNestedObjectKeys(
            getNestedObjectByPath(userFilter, pathArr),
            2
          );
          tempArr2 = [...tempArr2, ...returnValue];
          ischecked = true;
        }
      }
      if (!ischecked) {
        if (parentCheckboxes.length > 0) {
          let tempUserFilter = {};
          let isCheckedParent = false;
          for (let i = 0; i < parentCheckboxes.length; i++) {
            let parentCheckbox = parentCheckboxes[i] as HTMLInputElement;
            if (parentCheckbox.checked) {
              isCheckedParent = true;
              tempUserFilter = {
                ...tempUserFilter,
                ...userFilter[parentCheckbox.name.slice(2)],
              };
            }
          }
          if (isCheckedParent) {
            setBasins(getNestedObjectKeys(tempUserFilter, 2));
            setStations(getNestedObjectKeys(tempUserFilter, 3));
          } else {
            setBasins(getNestedObjectKeys(userFilter, 3));
            setStations(getNestedObjectKeys(userFilter, 4));
          }
        }
      } else {
        setBasins(tempArr);
        setStations(tempArr2);
      }
    });

    document.getElementById("basinsForm")?.addEventListener("input", () => {
      if (Object.keys(userFilter).length === 0) return;

      const inputs = document.getElementsByClassName("checkbox-3");
      let tempArr: string[] = [];

      const radios = document.querySelectorAll(
        'input[type="radio"][name="stations"]'
      ) as NodeListOf<HTMLInputElement>;
      radios.forEach((radio: HTMLInputElement) => {
        // if the radio input is checked, uncheck it
        if (radio.checked) {
          radio.checked = false;
        }
      });

      const parentCheckboxes = Array.from(checkboxes).filter(
        (checkbox, index) =>
          !checkbox.classList.contains("exclude") &&
          !checkbox.classList.contains("checkbox-3") &&
          (checkbox as HTMLInputElement).checked
      ) as HTMLInputElement[];

      if (
        parentCheckboxes.findIndex((cb) =>
          cb.classList.contains("checkbox-2")
        ) >= 0
      ) {
        for (let i = parentCheckboxes.length - 1; i >= 0; i--) {
          if (parentCheckboxes[i].classList.contains("checkbox-1")) {
            parentCheckboxes.splice(i, 1);
          }
        }
      }

      let ischecked = false;

      for (let i = 0; i < inputs.length; i++) {
        let temp = inputs[i] as HTMLInputElement;
        if (temp.checked) {
          let pathArr = findLocationPath(userFilter, temp.name.slice(2), 3, []);
          if (parentCheckboxes.length > 0) {
            let newPathArr = [];
            for (let j = 0; j < parentCheckboxes.length; j++) {
              let parentTemp = parentCheckboxes[j] as HTMLInputElement;
              for (let n = 0; n < pathArr.length; n++) {
                if (pathArr[n].includes(parentTemp.name.slice(2))) {
                  newPathArr.push(pathArr[n]);
                } else {
                  continue;
                }
              }
            }
            pathArr = newPathArr;
          }

          let returnValue = getNestedObjectKeys(
            getNestedObjectByPath(userFilter, pathArr),
            1
          );

          tempArr = [...tempArr, ...returnValue];
          ischecked = true;
        }
      }

      if (!ischecked) {
        if (parentCheckboxes.length > 0) {
          let tempUserFilter = {};
          if (parentCheckboxes[0].classList.contains("checkbox-1")) {
            for (let i = 0; i < parentCheckboxes.length; i++) {
              let parentCheckbox = parentCheckboxes[i] as HTMLInputElement;
              tempUserFilter = {
                ...tempUserFilter,
                ...userFilter[parentCheckbox.name.slice(2)],
              };
            }
            setStations(getNestedObjectKeys(tempUserFilter, 3));
          } else {
            for (let i = 0; i < parentCheckboxes.length; i++) {
              let pathArr = findLocationPath(
                userFilter,
                parentCheckboxes[i].name.slice(2),
                2,
                []
              );
              getNestedObjectByPath(userFilter, pathArr);
              tempUserFilter = {
                ...tempUserFilter,
                ...getNestedObjectByPath(userFilter, pathArr),
              };
            }
            setStations(getNestedObjectKeys(tempUserFilter, 2));
          }
        } else {
          setStations(getNestedObjectKeys(userFilter, 4));
        }
      } else {
        setStations(tempArr);
      }
    });

    document.getElementById("stationsForm")?.addEventListener("input", () => {
      if (Object.keys(userFilter).length === 0) return;

      const inputs = document.querySelectorAll(".checkbox-4");

      for (let i = 0; i < inputs.length; i++) {
        let temp = inputs[i] as HTMLInputElement;
        if (temp.checked) {
          let pathArr = findLocationPath(userFilter, temp.id, 4)[0]?.split(">");
          if (pathArr) {
            setSelectedStation(
              userFilter[pathArr[0]][pathArr[1]][pathArr[2]][pathArr[3]]
                .station_id
            );

            setSelectedStationName(pathArr[3]);

            setReaders(
              userFilter[pathArr[0]][pathArr[1]][pathArr[2]][pathArr[3]].readers
            );

            if (
              userFilter[pathArr[0]][pathArr[1]][pathArr[2]][pathArr[3]].lat
                .toString()
                .includes("°") ||
              userFilter[pathArr[0]][pathArr[1]][pathArr[2]][pathArr[3]].long
                .toString()
                .includes("°")
            ) {
              setLat(
                parseFloat(
                  userFilter[pathArr[0]][pathArr[1]][pathArr[2]][pathArr[3]].lat
                    .toString()
                    .replace("°", "")
                )
              );
              setLong(
                parseFloat(
                  userFilter[pathArr[0]][pathArr[1]][pathArr[2]][
                    pathArr[3]
                  ].long
                    .toString()
                    .replace("°", "")
                )
              );
            } else {
              setLat(
                parseFloat(
                  userFilter[pathArr[0]][pathArr[1]][pathArr[2]][pathArr[3]].lat
                    .toString()
                    .replace("°", "")
                )
              );
              setLong(
                parseFloat(
                  userFilter[pathArr[0]][pathArr[1]][pathArr[2]][
                    pathArr[3]
                  ].long
                    .toString()
                    .replace("°", "")
                )
              );
            }
          }
        }
      }
    });
  }, [userFilter, tableData]);

  useEffect(() => {
    if (Object.keys(userFilter).length === 0) return;

    const inputs = document.querySelectorAll(".checkbox-4");
    let tempArr: any[] = [];
    for (let i = 0; i < inputs.length; i++) {
      let temp = inputs[i] as HTMLInputElement;
      let path: any = findLocationPath(userFilter, temp.id, 4);
      if (path !== null && path.length > 0) {
        let pathArr = path[0].split(">");
        tempArr = [
          ...tempArr,
          userFilter[pathArr[0]][pathArr[1]][pathArr[2]][pathArr[3]].readers,
        ];
      }
    }
    setReaders(tempArr.flat());
  }, [stations]);

  const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  const filteredCheckboxes = Array.from(checkboxes).filter(
    (checkbox) => !checkbox.classList.contains("exclude")
  ) as HTMLInputElement[];

  useEffect(() => {
    const selectedCheckboxes = Array.from(checkboxes)
      .filter((checkbox: Element): checkbox is HTMLInputElement => {
        return (checkbox as HTMLInputElement).checked;
      })
      .map((checkbox: HTMLInputElement) => checkbox.value);

    if (selectedCheckboxes.length === 0) {
      setFilteredTableData(tableData);
    } else {
      let tempArr: any[] = [];

      // Filter tableData based on selected regions
      const regionCheckboxes = filteredCheckboxes.filter(
        (cb) => cb.classList.contains("checkbox-1") && cb.checked
      );
      if (regionCheckboxes.length > 0) {
        const selectedRegions = regionCheckboxes.map((cb) => cb.name.slice(2));
        tempArr = tableData.filter((station) =>
          selectedRegions.includes(station.region)
        );
      } else {
        tempArr = [...tableData];
      }

      // Filter the temporary array based on selected districts
      const districtCheckboxes = filteredCheckboxes.filter(
        (cb) => cb.classList.contains("checkbox-2") && cb.checked
      );
      if (districtCheckboxes.length > 0) {
        const selectedDistricts = districtCheckboxes.map((cb) =>
          cb.name.slice(2)
        );
        tempArr = tempArr.filter((station) =>
          selectedDistricts.includes(station.district)
        );
      }

      // Filter the temporary array based on selected basins
      const basinCheckboxes = filteredCheckboxes.filter(
        (cb) => cb.classList.contains("checkbox-3") && cb.checked
      );
      if (basinCheckboxes.length > 0) {
        const selectedBasins = basinCheckboxes.map((cb) => cb.name.slice(2));
        tempArr = tempArr.filter((station) =>
          selectedBasins.includes(station.basin)
        );
      }

      setFilteredTableData(tempArr);
    }
  }, [stations, tableData]);

  function displayDashboardFilter(
    userFilter: any,
    filterArray: any,
    level: any
  ) {
    const uniqueArr = [...new Set(filterArray)];

    return Array.isArray(uniqueArr)
      ? uniqueArr
          .sort((a: any, b: any) =>
            a.toLowerCase() > b.toLowerCase() ? 1 : -1
          )
          .map((element: any, index) => {
            return (
              <div
                className="flex items-center"
                key={"df_" + level + "_" + index + "______"}
              >
                <input
                  className={`checkbox-` + level}
                  type="checkbox"
                  name={level + "-" + element}
                />
                <label
                  htmlFor="checkbox"
                  className="text-mobile lg:text-sm tooltip pl-2 truncate"
                  title={element}
                >
                  {" "}
                  {element}{" "}
                </label>
              </div>
            );
          })
      : null;
  }

  function riverLevel() {
    if (dataType === "River Level") return;

    const inputs = document.querySelectorAll(".checkbox-4");

    for (let i = 0; i < inputs.length; i++) {
      let temp = inputs[i] as HTMLInputElement;
      temp.checked = false;
    }

    let river = document.getElementById("riverDiv") as HTMLDivElement;
    let rainfall = document.getElementById("rainfallDiv") as HTMLDivElement;
    let temperature = document.getElementById(
      "temperatureDiv"
    ) as HTMLDivElement;

    let changingText = document.getElementById(
      "changingFilterHead"
    ) as HTMLParagraphElement;
    let riverIconFunc = document.getElementById(
      "riverIcon"
    ) as HTMLImageElement;
    let rainIconFunc = document.getElementById("rainIcon") as HTMLImageElement;
    let tempIconFunc = document.getElementById(
      "temperatureIcon"
    ) as HTMLImageElement;
    tempIconFunc.src = tempWhite;

    riverIconFunc.src = riverIconGreen;
    rainIconFunc.src = rainIconWhite;

    river.classList.remove("bg-[#00BB59]", "text-white");
    river.classList.add("bg-white", "text-[#00BB59]");
    rainfall.classList.add("bg-[#00BB59]", "text-white");
    rainfall.classList.remove("text-[#00BB59]", "bg-white");
    temperature.classList.add("bg-[#00BB59]", "text-white");
    temperature.classList.remove("text-[#00BB59]", "bg-white");
    changingText.innerText = "Gauge Readers";
    setDataType("River Level");
    setSelectedStation("");
    setSelectedStationName("");
    setStats({});
  }

  function rainfall() {
    if (dataType === "Rainfall") return;

    const inputs = document.querySelectorAll(".checkbox-4");

    for (let i = 0; i < inputs.length; i++) {
      let temp = inputs[i] as HTMLInputElement;
      temp.checked = false;
    }

    let river = document.getElementById("riverDiv") as HTMLDivElement;
    let rainfall = document.getElementById("rainfallDiv") as HTMLDivElement;
    let temperature = document.getElementById(
      "temperatureDiv"
    ) as HTMLDivElement;

    let riverIconFunc = document.getElementById(
      "riverIcon"
    ) as HTMLImageElement;
    let rainIconFunc = document.getElementById("rainIcon") as HTMLImageElement;
    let tempIconFunc = document.getElementById(
      "temperatureIcon"
    ) as HTMLImageElement;

    let changingText = document.getElementById(
      "changingFilterHead"
    ) as HTMLParagraphElement;
    tempIconFunc.src = tempWhite;
    riverIconFunc.src = riverIcon;
    rainIconFunc.src = rainIcon;

    changingText.innerText = "Observers";
    rainfall.classList.add("text-[#00BB59]", "bg-white");
    rainfall.classList.remove("bg-[#00BB59]", "text-white");
    river.classList.add("bg-[#00BB59]", "text-white");
    river.classList.remove("text-[#00BB59]", "bg-white");
    temperature.classList.add("bg-[#00BB59]", "text-white");
    temperature.classList.remove("text-[#00BB59]", "bg-white");
    setDataType("Rainfall");
    setSelectedStation("");
    setSelectedStationName("");
    setStats({});
  }

  function temperature() {
    if (dataType === "Temperature") return;

    const inputs = document.querySelectorAll(".checkbox-4");

    for (let i = 0; i < inputs.length; i++) {
      let temp = inputs[i] as HTMLInputElement;
      temp.checked = false;
    }

    let river = document.getElementById("riverDiv") as HTMLDivElement;
    let rainfall = document.getElementById("rainfallDiv") as HTMLDivElement;
    let temperature = document.getElementById(
      "temperatureDiv"
    ) as HTMLDivElement;

    let riverIconFunc = document.getElementById(
      "riverIcon"
    ) as HTMLImageElement;
    let rainIconFunc = document.getElementById("rainIcon") as HTMLImageElement;
    let tempIconFunc = document.getElementById(
      "temperatureIcon"
    ) as HTMLImageElement;

    let changingText = document.getElementById(
      "changingFilterHead"
    ) as HTMLParagraphElement;
    tempIconFunc.src = tempGreen;
    riverIconFunc.src = riverIcon;
    rainIconFunc.src = rainIconWhite;

    changingText.innerText = "Observers";
    temperature.classList.add("text-[#00BB59]", "bg-white");
    temperature.classList.remove("bg-[#00BB59]", "text-white");
    river.classList.add("bg-[#00BB59]", "text-white");
    river.classList.remove("text-[#00BB59]", "bg-white");
    rainfall.classList.add("bg-[#00BB59]", "text-white");
    rainfall.classList.remove("text-[#00BB59]", "bg-white");

    setDataType("Temperature");
    setSelectedStation("");
    setSelectedStationName("");
    setStats({});
  }

  let filteredData: any[];

  if (dataType === "Temperature") {
    const tminDates = stats?.tmin?.dates || [];
    const tminData = stats?.tmin?.data || [];
    const tmaxDates = stats?.tmax?.dates || [];
    const tmaxData = stats?.tmax?.data || [];

    filteredData = tminDates.reduce((result: any, date: any, index: any) => {
      const tminValue = tminData[index];
      const tmaxValue = tmaxData[index];

      if (tminValue !== 0 && tmaxValue !== 0) {
        result.push({
          date: date,
          tmin: tminValue,
          tmax: tmaxValue,
        });
      }

      return result;
    }, []);
  } else {
    filteredData =
      stats && Object.keys(stats).length === 0
        ? null
        : stats?.dates?.map((date: any, index: string | number) => ({
            date: date,
            data: stats.data[index],
            interpolate: stats.interpolate[index],
          }));
  }

  const CustomizedDot = (props: any) => {
    const { cx, cy, stroke, payload, value } = props;
    if (!payload.interpolate) {
      return (
        <svg
          x={cx - 5}
          y={cy - 5}
          width="10"
          height="10"
          fill="black"
          viewBox="0 0 1024 1024"
        >
          <circle cx="512" cy="512" r="512" />
        </svg>
      );
    }
    return (
      <svg
        x={cx - 5}
        y={cy - 5}
        width="10"
        height="10"
        fill="none"
        viewBox="0 0 1024 1024"
      >
        <circle cx="512" cy="512" r="512" stroke="black" strokeWidth={"100"} />
      </svg>
    );
  };

  function CustomTooltipRainFall(props: any) {
    const { payload, label, active } = props;
    if (active && payload && payload.length) {
      return (
        <div className="flex bg-white bg-opacity-60 justify-center p-3 flex-col">
          <p className="">{`Date: ${label}`}</p>
          <p className="">{`${payload[0].payload.onhover_data}`}</p>
        </div>
      );
    }

    return null;
  }

  function CustomTooltipRiverLevel(props: any) {
    const { payload, label, active } = props;
    if (active) {
      return (
        <div className="flex bg-white bg-opacity-60 justify-center p-3 flex-col">
          <p className="">{`Date : ${label}`}</p>
          <p className=""> {`River Level : ${payload[0].value}`} </p>
        </div>
      );
    }

    return null;
  }



  function CustomTooltipTemperature(props: any) {
    const { payload, label, active } = props;



    if (active && payload && payload.length) {
      return (
        <div className="flex bg-white bg-opacity-60 justify-center p-3 flex-col">
          <p>{`Date: ${label}`}</p>
          {<p>{`tmin : ${payload[0].payload.tmin_display} °`}</p>}

          {<p>{`tmax : ${payload[0].payload.tmax_display} °`}</p>}
        </div>
      );
    }

    return null;
  }

  const CustomBar = (props: any) => {
    const { x, y, height, width, fill, graph_data } = props;
    if (props.value === 0) {
      return null;
    }

    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height < 0 ? height * -1 : height}
        fill={fill}
      />
    );
  };

  const CSVheaders = [
    "Station ID",
    "Station Name",
    "Raw Data",
    "Reported Data (m)",
    "Date",
    "Basin",
    "District",
    "Region",
    "Comment",
  ];

  const xMin = filteredData
    ? Math.min(...filteredData.map((d: any) => d.value))
    : 0;
  const xMax = filteredData
    ? Math.max(...filteredData.map((d: any) => d.value))
    : 0;
  const xRange = xMax - xMin;
  const interval = xRange / 6;

  useEffect(() => {
    window.onclick = function (e) {
      let checkIfHidden = document.getElementById(
        "countryDropdown"
      ) as HTMLElement;
      if (
        checkIfHidden !== null &&
        !checkIfHidden.classList.contains("hidden")
      ) {
        const target = e.target as Element;
        if (!target.classList.contains("stay")) {
          removeCountries();
        }
      }
    };
  }, []);

  const renderStationLegend = (props: any) => {
    if (dataType === "Temperature") {
      return (
        <div className="text-center flex flex-col justify-center items-center gap-2 pb-2 lg:text-xs xl:text-xs 2xl:text-base">
          <div className="flex flex-col justify-center items-center gap-1">
            <div className="flex gap-1 flex-col items-center">
              <div className="flex gap-1">
                <p> Temperature over time for </p>
                <p className="font-bold"> Station : </p> {selectedStationName}
                <p className="font-bold"> ID : </p> {selectedStation}
              </div>

              <div className="flex gap-1">
                <p className="font-bold"> Lowest t-min : </p>{" "}
                {stats?.tmin_min?.toFixed(2)}
                <p className="font-bold"> Highest t-min : </p>{" "}
                {stats?.tmin_max?.toFixed(2)}
                <p className="font-bold"> Average t-min : </p>{" "}
                {stats?.tmin_average?.toFixed(2)}
              </div>

              <div className="flex gap-1">
                <p className="font-bold"> Lowest t-max : </p>{" "}
                {stats?.tmax_min?.toFixed(2)}
                <p className="font-bold"> Highest t-max : </p>{" "}
                {stats?.tmax_average?.toFixed(2)}
                <p className="font-bold"> Average t-max : </p>{" "}
                {stats?.tmax_average?.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (dataType === "River Level") {
      let min = 0,
        max = 0,
        average = 0;
      for (let i = 0; i < stats.data?.length; i++) {
        if (stats.data[i] > max) {
          max = stats.data[i];
        }

        if (stats.data[i] < min && stats.data[i] >= 0) {
          min = stats.data[i];
        }

        average += stats.data[i];
      }

      if (props) {
        return (
          <div className="text-center flex flex-col justify-center items-center gap-2 pb-2 lg:text-xs xl:text-xs 2xl:text-base">
            <div className="flex flex-col justify-center  gap-1">
              River levels over time for{" "}
              <div className="flex gap-1">
                {" "}
                <p className="font-bold"> Station : </p>{" "}
                <p className="truncate"> {selectedStationName} </p>{" "}
                <p className="font-bold"> ID : </p> {selectedStation}{" "}
                <p className="font-bold"> Min : </p> {min.toFixed(2)}{" "}
                <p className="font-bold"> Max : </p> {max.toFixed(2)}{" "}
                <p className="font-bold"> Average : </p>{" "}
                {(average / stats.data?.length).toFixed(2)}{" "}
              </div>
            </div>
            <div className="text-center flex justify-center items-center gap-2">
              <svg width="10" height="10" fill="black" viewBox="0 0 1024 1024">
                <circle cx="512" cy="512" r="512" />
              </svg>{" "}
              : Observation -{" "}
              <svg width="10" height="10" fill="none" viewBox="0 0 1024 1024">
                <circle
                  cx="512"
                  cy="512"
                  r="512"
                  stroke="black"
                  strokeWidth={"100"}
                />
              </svg>{" "}
              : Interpolated
            </div>
          </div>
        );
      }
    }

    return (
      <div className="text-center flex flex-col justify-center items-center gap-2 pb-2 lg:text-xs xl:text-xs 2xl:text-base">
        <div className="flex flex-col justify-center items-center gap-1">
          Rainfall over time for{" "}
          <div className="flex gap-1">
            {" "}
            <p className="font-bold"> Station : </p> {selectedStationName}{" "}
            <p className="font-bold"> ID : </p> {selectedStation}{" "}
            <p className="font-bold"> Min : </p>{" "}
            {rainfallGraphData.minimum?.toFixed(2)}{" "}
            <p className="font-bold"> Max : </p>{" "}
            {rainfallGraphData.maximum?.toFixed(2)}{" "}
            <p className="font-bold"> Average : </p>{" "}
            {rainfallGraphData.average?.toFixed(2)}{" "}
          </div>
        </div>
      </div>
    );
  };

  let filteredTempStats = dataType === "Temperature" && stats?.data?.filter((dataPoint : any) => dataPoint.tmin !== 0 || dataPoint.tmax !== 0 || !Number.isInteger(dataPoint.tmin) || !Number.isInteger(dataPoint.tmax));

  let adjustedTempStats = dataType === "Temperature" && filteredTempStats?.map((dataPoint: any) => ({
    ...dataPoint,
    tmin: dataPoint.tmin === 0 ? null : dataPoint.tmin,
    tmax: dataPoint.tmax === 0 ? null : dataPoint.tmax,
  }));



  // Mobile change of filter, Region, district, basin and stations. Converts the div from green to white if selected.
  function handleFilterChange(filter: number) {
    if (windowSizeBolean) return;
  
    interface FilterElements {
      [key: string]: HTMLElement | null;
    }
  
    //
    const filters: FilterElements = {
      regions: document.getElementById("regionsFilter"),
      districts: document.getElementById("districtsFilter"),
      basins: document.getElementById("basinsFilter"),
      stations: document.getElementById("stationsFilter")
    };
  
    const toggleClasses = (element: HTMLElement | null, addClasses: string[], removeClasses: string[]) => {
      if (element?.previousElementSibling instanceof HTMLElement) {
        element.previousElementSibling.classList.add(...addClasses);
        element.previousElementSibling.classList.remove(...removeClasses);
      }
    };
  
    const showSelectedFilter = (selected: string) => {
      Object.entries(filters).forEach(([key, element]) => {
        const isChosen = key === selected;
        if (element) {
          element.classList.toggle("hidden", !isChosen);
          toggleClasses(
            element,
            isChosen ? ["text-[#00BB59]", "bg-white"] : ["bg-[#00BB59]", "text-white"], 
            isChosen ? ["bg-[#00BB59]", "text-white"] : ["text-[#00BB59]", "bg-white"]
          );
        }
      });
    };
  
    const filterMap: { [key: number]: string } = {
      1: 'regions',
      2: 'districts',
      3: 'basins',
      4: 'stations'
    };
  
    const selectedFilter = filterMap[filter];
    if (selectedFilter) {
      showSelectedFilter(selectedFilter);
    }
  }


  const CustomDot = ({ cx, cy, payload }: any) => {
    const { tmin, tmax } = payload; // Assuming payload contains tmin and tmax

    if (tmin === 0 || tmax === 0) {
      return null; // Return null to not render the dot if both tmin and tmax are 0
    }


    return <Dot cx={cx} cy={cy} r={4} strokeWidth={2} />;
  };

  function displayGraphs() {
    if (selectedStation === "") {
      return (
        <div className="w-full h-full flex flex-col justify-center items-center bg-white">
          {" "}
          <img
            src={
              "https://static.wixstatic.com/media/a2eddc_fe73a659d6354f3db5fab23638f7041b~mv2.png/v1/fill/w_228,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Water-in-Sight-logo.png"
            }
          />{" "}
          Please Select a station to display the graph{" "}
        </div>
      );
    }

    if (
      filteredData?.length === 0 &&
      Object.keys(rainfallGraphData)?.length === 0
    ) {
      return (
        <div className="w-full h-full flex flex-col justify-center items-center bg-white">
          {" "}
          <img
            src={
              "https://static.wixstatic.com/media/a2eddc_fe73a659d6354f3db5fab23638f7041b~mv2.png/v1/fill/w_228,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Water-in-Sight-logo.png"
            }
          />{" "}
          No Data for this station yet{" "}
        </div>
      );
    }

    if (dataType === "River Level") {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={filteredData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="date"
              type="category"
              interval={
                windowSizeBolean
                  ? Math.ceil(filteredData?.length / 7)
                  : Math.ceil(filteredData?.length / 3)
              }
              tick={{ fontSize: 12 }}
            />
            <YAxis
              dataKey={"data"}
              label={{
                value: "Level above gauge datum (m)",
                angle: -90,
                dx: -25,
              }}
              tickFormatter={(value) =>
                (Math.round(value * 100) / 100).toString()
              }
            />
            <Legend verticalAlign="top" content={renderStationLegend(1)} />

            <Tooltip
              content={<CustomTooltipRiverLevel />}
              wrapperStyle={{ backgroundColor: "transparent" }}
              cursor={false}
            />
            <Area
              type="monotone"
              dataKey="data"
              stroke="#9CDDBE"
              fill="#9CDDBE"
              textDecoration={"red"}
              dot={<CustomizedDot />}
              isAnimationActive={false}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    } else if (dataType === "Rainfall") {
      // Find the maximum Y-axis value in the data
      const maxYValue = rainfallGraphData.maximum;
      // Calculate the upper bound for the Y-axis domain

      const upperBound = maxYValue > 0 ? maxYValue : 1;

      return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={rainfallGraphData.data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dates"
              interval={
                windowSizeBolean
                  ? Math.ceil(rainfallGraphData.data?.length / 7)
                  : Math.ceil(rainfallGraphData.data?.length / 3)
              }
              tickCount={7}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              dataKey="graph_data"
              label={{
                value: "mm/24 hrs",
                angle: -90,
                position: "insideLeft",
              }}
              domain={[0, upperBound]}
              allowDataOverflow={true}
            />
            <Legend verticalAlign="top" content={renderStationLegend(0)} />

            <Tooltip
              content={<CustomTooltipRainFall />}
              wrapperStyle={{ backgroundColor: "transparent" }}
              cursor={false}
            />

            <Bar
              dataKey="graph_data"
              fill="#9CDDBE"
              minPointSize={5}
              style={{ color: "black" }}
              shape={<CustomBar />}
              isAnimationActive={false}
            >
              <LabelList
                dataKey="traces"
                content={<RenderCustomizedLabel />}
                key={dateRange}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (dataType === "Temperature") {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={adjustedTempStats}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <XAxis
              dataKey="date"
              tickCount={7}
              tick={{ fontSize: 12 }}
              interval={
                windowSizeBolean
                  ? Math.ceil(adjustedTempStats?.length / 7)
                  : Math.ceil(adjustedTempStats?.length / 3)
              }
            />
            <YAxis
              label={{
                value: "Temperature (°C)",
                angle: -90,
                position: "insideLeft",
                offset: 10,
              }}
            />

            <Tooltip
              content={<CustomTooltipTemperature />}
              wrapperStyle={{ backgroundColor: "transparent" }}
              cursor={false}
            />
            <Line
              type="monotone"
              dataKey="tmin"
              stroke="#8884d8"
              name="tmin"
              connectNulls={true}
              dot={<CustomDot />}
            />
            <Line
              type="monotone"
              dataKey="tmax"
              stroke="#82ca9d"
              name="tmax"
              connectNulls={true}
              dot={<CustomDot />}
            />
            <Legend verticalAlign="top" content={renderStationLegend(0)} />
          </LineChart>
        </ResponsiveContainer>
      );
    }
  }

  return (
    <div className="lg:w-9/12 xl:w-10/12 h-full flex flex-col w-full">
      <div className="flex h-[72px] lg:h-18 flex-wrap-reverse lg:flex-nowrap lg:flex-row">
        <button
          className={`w-1/3 lg:w-1/4 lg:h-18 pr-1 lg:p-4 lg:pt-0 cursor-pointer  ${
            getCookie("selectedCountry") === "Mozambique" && " opacity-50"
          }`}
          onClick={() => riverLevel()}
          disabled={getCookie("selectedCountry") === "Mozambique"}
        >
          <div
            className={`w-full  h-[28px] lg:h-full flex justify-center items-center gap-2 text-mobile lg:text-base xl:text-xl ${
              getCookie("selectedCountry") === "Mozambique"
                ? "bg-gray-500 text-white"
                : "bg-white text-[#00BB59]  "
            }`}
            id="riverDiv"
          >
            <img
              src={
                getCookie("selectedCountry") === "Mozambique"
                  ? riverIcon
                  : riverIconGreen
              }
              className=" w-[13px] h-[10px] lg:h-auto lg:w-auto"
              id="riverIcon"
            />
            <p> River levels</p>
          </div>
        </button>

        <button
          className="w-1/3 lg:w-1/4   lg:h-18  pl-1 lg:p-4 lg:pt-0 cursor-pointer"
          onClick={() => rainfall()}
        >
          <div
            className={`w-full h-[28px] lg:h-full flex justify-center items-center  gap-2 text-mobile lg:text-base xl:text-xl ${
              getCookie("selectedCountry") === "Mozambique"
                ? "bg-white text-[#00BB59]"
                : "text-white bg-[#00BB59]"
            }`}
            id="rainfallDiv"
          >
            <img
              src={
                getCookie("selectedCountry") === "Mozambique"
                  ? rainIcon
                  : rainIconWhite
              }
              className=" w-[13px] h-[10px] lg:h-auto lg:w-auto"
              id="rainIcon"
            />
            <p> Rainfall</p>
          </div>
        </button>

        <button
          className={`w-1/3 lg:w-1/4 lg:h-18 pl-1 lg:p-4 lg:pt-0 cursor-pointer ${
            getCookie("selectedCountry") === "Malawi" && " opacity-50"
          } `}
          onClick={() => temperature()}
          disabled={getCookie("selectedCountry") === "Malawi"}
        >
          <div
            className={`w-full  h-[28px] lg:h-full flex justify-center items-center  text-white gap-2 text-mobile lg:text-base xl:text-xl ${
              getCookie("selectedCountry") === "Malawi"
                ? "bg-gray-500 "
                : "bg-[#00BB59]"
            }`}
            id="temperatureDiv"
          >
            <img
              src={tempWhite}
              className=" w-[13px] h-[10px] lg:h-[40px] lg:w-[40px] object-contain	"
              id="temperatureIcon"
            />
            <p> Temperature</p>
          </div>
        </button>

        <div className="w-full  lg:w-1/4 h-[38px] lg:h-18 pb-[6px] lg:p-4 lg:pt-0">
          <div className="w-full  h-full flex justify-center items-center bg-white lg:bg-[#00BB59] text-[#00BB59] lg:text-white gap-2 text-base xl:text-xl">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={false}
              className="bg-transparent text-center w-auto lg:w-3/4 ml-2 lg:ml-[12.5%] focus:outline-none text-mobile lg:text-base"
              selected={startDate}
            />
            <div className="w-1/2 lg:hidden flex justify-end mr-2 gap-4">
              <div className=" stay relative">
                {" "}
                <p
                  className="hover:bg-gray-200 p-2 stay -rotate-90 text-center text-black"
                  onClick={() => displayCountriesDropDown()}
                >
                  {"<"}
                </p>
                <div
                  className=" absolute top-12 right-0 w-48 bg-white border-2 border-black flex flex-col items-start pt-4 gap-4 overflow-hidden pb-8 z-[9999] stay hidden text-sm"
                  id="countryDropdown"
                >
                  {displayCountries()}
                </div>
              </div>
              <img
                src={profilePic}
                className="w-[27px] h-[27px] mt-[6px]"
                onClick={() => logoutMobile()}
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex-col lg:flex-row flex-auto flex mt-[23px] lg:mt-0 lg:pl-4 overflow-hidden relative">
        <div className="h-[110px] lg:h-full w-full lg:w-[111px] xl:w-[211px] flex justify-center gap-2 lg:flex-col overflow-x-auto lg:overflow-visible scrollbar-hide">
          <div className="flex flex-col w-[82px]  lg:h-1/5 lg:w-[111px] xl:w-[211px]  ">
            <div
              className=" bg-white lg:bg-[#00BB59] flex h-[28px] lg:h-auto justify-center items-center text-[#00BB59] lg:text-white text-mobile lg:text-base xl:text-xl w-[82px] lg:w-full"
              onClick={() => handleFilterChange(1)}
            >
              {" "}
              Region{" "}
            </div>

            <div
              className="flex-auto w-full lg:flex flex-col overflow-hidden absolute left-1 top-12 lg:static"
              id="regionsFilter"
            >
              <div className="w-full flex-auto overflow-auto mt-2 pl-2 scrollbar-hide">
                <form id="regionsForm" className="flex gap-2 lg:block">
                  {Object.keys(userFilter).length !== 0 &&
                    displayDashboardFilter(userFilter, regions, "1")}
                </form>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[82px]  lg:h-1/5 lg:w-full">
            <div
              className=" bg-[#00BB59] flex h-[28px] lg:h-auto justify-center items-center text-white text-mobile lg:text-base xl:text-xl w-[82px] lg:w-full"
              onClick={() => handleFilterChange(2)}
            >
              {" "}
              District{" "}
            </div>
            <div
              className="flex-auto w-full lg:flex flex-col overflow-hidden absolute left-1 top-12 lg:static hidden"
              id="districtsFilter"
            >
              <div className="w-full flex-auto overflow-auto mt-2 pl-2 scrollbar-hide">
                <form id="districtsForm" className="flex gap-2 lg:block">
                  {Object.keys(userFilter).length !== 0 &&
                    displayDashboardFilter(userFilter, districts, "2")}
                </form>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[82px]  lg:h-1/5 lg:w-full">
            <div
              className=" bg-[#00BB59] flex h-[28px] lg:h-auto justify-center items-center text-white text-mobile lg:text-base xl:text-xl w-[82px] lg:w-full"
              onClick={() => handleFilterChange(3)}
            >
              {" "}
              Basin{" "}
            </div>
            <div
              className="flex-auto w-full lg:flex flex-col overflow-hidden absolute left-1 top-12 lg:static hidden"
              id="basinsFilter"
            >
              <div className="w-full flex-auto overflow-auto mt-2 pl-2 scrollbar-hide">
                <form id="basinsForm" className="flex gap-2 lg:block">
                  {Object.keys(userFilter).length !== 0 &&
                    displayDashboardFilter(userFilter, basins, "3")}
                </form>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[82px]  lg:h-1/5 lg:w-full">
            <div
              className=" bg-[#00BB59] flex h-[28px] lg:h-auto justify-center items-center text-white text-mobile lg:text-base xl:text-xl w-[82px] lg:w-full"
              onClick={() => handleFilterChange(4)}
            >
              {" "}
              Station{" "}
            </div>

            <div className="w-full items-center hidden lg:flex ">
              <input
                className=" px-2 w-10/12 focus:outline-none  bg-white  "
                onChange={(e) => setStationSearch(e.target.value)}
                placeholder="search..."
                id="searchBar"
                autoComplete="off"
                value={stationSearch}
              />

              <div className="w-2/12 flex justify-end pr-3 bg-white h-full">
                <img
                  src={searchIcon}
                  width="20px"
                  className="hidden xl:block"
                  height={"20px"}
                />
              </div>
            </div>
            <div
              className="flex-auto w-full lg:flex flex-col overflow-hidden absolute left-1 top-12 lg:static hidden"
              id="stationsFilter"
            >
              <div className="w-full flex-auto overflow-auto mt-2 pl-2 scrollbar-hide">
                <form id="stationsForm" className="flex gap-2 lg:block">
                  {Object.keys(userFilter).length !== 0 &&
                    displayDashboardFilterRadio(
                      userFilter,
                      stations,
                      "4",
                      stationSearch
                    )}
                </form>
              </div>
            </div>
          </div>

          <div className="hidden lg:flex flex-col w-[82px]  lg:h-1/5 lg:w-full">
            <div className=" bg-[#00BB59] flex h-[28px] lg:h-auto justify-center items-center text-white text-mobile lg:text-base xl:text-xl w-[82px] lg:w-full">
              <p id="changingFilterHead">Gauge Readers</p>
            </div>
            <div className="flex-auto w-full lg:flex flex-col overflow-hidden hidden">
              <div
                className="w-full flex-auto overflow-auto mt-2 pl-2"
                id="readersForm"
              >
                {Object.keys(userFilter).length !== 0 &&
                  displayDashboardUsers(readers)}
              </div>
            </div>
          </div>
        </div>

        <div className="flex-auto flex flex-col gap-4 overflow-x-hidden lg:ml-4 text-mobile lg:text-base">
          <div className="relative h-1/3">
            <div className="w-full h-full overflow-x-scroll  lg:mx-4">
              <div className="max-w-full h-full flex flex-col mt-4">
                {displayStationsDataCenter(filteredTableData, dataType)}
              </div>
              <button
                className={` p-2 bg-[#00B863] hover:bg-[#028f4d] hidden lg:flex items-center absolute right-24 bottom-2 gap-2 text-white ${
                  dataType !== "Rainfall" && "hidden lg:hidden"
                }`}
                onClick={() =>
                  downloadExcelClimSoft(
                    filteredTableData,
                    "Climsoft_Table.xlsx"
                  )
                }
                disabled={dataType !== "Rainfall" && true}
              >
                {" "}
                <img src={downloadIcon} className="h-6" />
                <p>Climsoft</p>
              </button>
              <button
                className="p-2 bg-[#00B863] hover:bg-[#028f4d] hidden lg:flex items-center absolute right-0 bottom-2 gap-2 text-white"
                onClick={() =>
                  downloadExcelFromArrayOfObjects(
                    filteredTableData,
                    "WIS_Table_Data.xlsx"
                  )
                }
              >
                {" "}
                <img src={downloadIcon} className="h-6" />
                <p>CSV</p>
              </button>
            </div>
          </div>
          <div className="flex w-full h-2/3  lg:px-4 gap-4 flex-wrap xl:flex-nowrap overflow-auto mb-16 lg:mb-0">
            <div className="w-full xl:w-3/4 h-[360px] xl:h-auto">
              {displayGraphs()}
            </div>
            <div className="w-full xl:w-1/4 bg-red-500 h-[160px] xl:h-auto">
              <MapContainer
                center={selectedCountryCenter()}
                zoom={selectedCountryZoom()}
                scrollWheelZoom={false}
                className=" w-full h-full z-0"
                id="map"
              >
                <GeoJSON data={data}></GeoJSON>
                {lat && long && (
                  <Marker position={[lat, long]} icon={icon}></Marker>
                )}
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
