import dashboardIcon from "./assets/dashboardIcon.svg";
import messageIcon from "./assets/messageIcon.svg";
import locationIcon from "./assets/locationIcon.svg";
import arrowDown from "./assets/arrowDown.svg";
import profilePic from "./assets/profilePic.svg";


import { url } from "./components/variables";
import { useNavigate } from "react-router-dom";
import { cookieExsist, eraseCookie, getCookie } from "./components/Api/Cookies";
import { useEffect } from "react";

export function handleLogOut () {
  eraseCookie("acessToken") 
  eraseCookie("username") 
  window.location.reload()
}
export function displayCountries(){
  let x = getCookie("countries")
  let countries = x === null ? null : JSON.parse(x)

  if (countries.length === 0) return;

  return Array.isArray(countries)
  ? countries.map((country:any, index) => {
      return (
        <button key={"country_list_"+index} className="pl-10 px-2 truncate hover:bg-gray-200 hover:text-black w-full text-start stay" onClick={()=>setCountryCookie(country)}> {country}</button> 
      )
    })
  : null;
}

export function setCountryCookie(country:string){
  let farFutureDate = new Date("January 1, 2100 00:00:00");
  document.cookie = `selectedCountry=${country}; expires=${farFutureDate.toUTCString()}; path=/`
  window.location.reload()
}

export function displayCountriesDropDown(){
  let dropdown = document.getElementById("countryDropdown") as HTMLElement
  dropdown.classList.remove("hidden")

}


export function removeCountries(){
  let dropdown = document.getElementById("countryDropdown") as HTMLElement
  dropdown.classList.add("hidden")
}

export default function Dashboard() {
  const navigate = useNavigate()




  useEffect(() => {
    window.onclick = function (e) {
      let checkIfHidden = document.getElementById("countryDropdown") as HTMLElement
      if(!checkIfHidden.classList.contains("hidden")){
        
        const target = e.target as Element;
            if (!target.classList.contains("stay")) {
              removeCountries();
            }
      }
    };
  }, []);


useEffect(()=>{
  if(!cookieExsist("acessToken")){
    navigate("login")
  }
})


  return (
    <div className="lg:w-3/12 xl:w-2/12 h-full bg-white flex-col hidden lg:flex justify-between">
      <div className="flex flex-col">
      <img
        src={
          "https://static.wixstatic.com/media/a2eddc_fe73a659d6354f3db5fab23638f7041b~mv2.png/v1/fill/w_228,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Water-in-Sight-logo.png"
        }
        className="w-8/12 mx-auto pt-8 mb-12 cursor-pointer"
      />
      <div className={`h-24 w-full flex justify-center items-center gap-6 pl-2 cursor-pointer ${window.location.href === url + "home"? "bg-[#9CDDBE] bg-opacity-20 border-r-2 border-[#5AC590]" :  ""}`} onClick={()=>navigate("/home")}>
        <div className="flex gap-4 w-10/12">
        <img src={dashboardIcon}></img>
        <p className="text-lg xl:text-xl"> Data Center</p>
        </div>

      </div>

      <div className={`h-24 w-full flex justify-center items-center gap-6 pl-2 cursor-pointer ${window.location.href.includes(url + "wischat") ? "bg-[#9CDDBE] bg-opacity-20 border-r-2 border-[#5AC590]" :  ""}`} onClick={()=>navigate("/wischat")}>
      <div className="flex gap-4 w-10/12">
        <img src={messageIcon}></img>
        <p className="text-lg xl:text-xl"> Chat App</p>
        </div>
      </div>

      <div className={`h-24 w-full flex justify-center items-center gap-6 pl-2 cursor-pointer ${window.location.href === url + "stations"? "bg-[#9CDDBE] bg-opacity-20 border-r-2 border-[#5AC590]" :  ""}`} onClick={()=>navigate("/stations")}>
      <div className="flex gap-4 w-10/12">
        <img src={locationIcon}></img>
        <p className="text-lg xl:text-xl"> Stations</p>
        </div>
      </div>
      </div>
        <div className=" mb-2 w-full flex flex-col justify-center items-start gap-3">

          <div className="w-4/5 mx-auto text-center mb-10 flex flex-col items-center text-xs">
            <h1 className="text-lg font-bold">Disclaimer!</h1>
          Information in the Data Center is for piloting purposes and should not be considered as official or validated by partners. Accuracy, completeness, or reliability of any of the information provided is not guaranteed.
          </div>

          <div className="flex gap-3 items-end h-12 w-4/5 mx-auto relative">
          <img src={profilePic} className="w-12 h-12" />
            <p className="pb-2  truncate">
            {getCookie("username")}
            </p>
            <div className="rotate-90 pb-2 hover:bg-gray-200 stay"> <p className="hover:bg-gray-200 p-2 pb-0 stay cursor-pointer" onClick={()=>displayCountriesDropDown()}>{"<"}</p> </div>
            
            <div className=" absolute left-16 bottom-8  w-48 bg-white border-2 border-black flex flex-col items-start pt-4 gap-4 overflow-hidden pb-8 z-[9999] stay hidden" id="countryDropdown">
                  {displayCountries()}        
            </div>
            
          </div>
            <button className="bg-[#00BB59] text-white h-12 mx-auto w-4/5 cursor-pointer" onClick={()=> handleLogOut()}> Logout </button>
        </div>
    </div>
  );
}
