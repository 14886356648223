import { findLocationPath } from "./Stats";


export function getStationId(userFilter:any, station:any ){
  let path = findLocationPath(userFilter, station, 4)

  let arr  = path[0]?.split(">")

  if (Array.isArray(arr)) return userFilter[arr[0]][arr[1]][arr[2]][arr[3]].station_id

  return
}

export function displayDashboardFilterRadio(userFilter:any, filterArray : any, level:string, stationSearch:string) {
  if(filterArray === null || filterArray=== undefined || stationSearch === undefined) return
 
  const searchArray = filterArray.filter((stationName:string) => {

    const trimmedString = stationName.trim(); // Remove leading/trailing spaces
    const lowercaseString = trimmedString.toLowerCase(); // Convert to lowercase

    const stationID = getStationId(userFilter, stationName)
    const trimmedID = stationID === undefined ? "" : stationID.trim()
    const lowerCaseID= trimmedID.toLowerCase()
    return lowercaseString.includes(stationSearch.toLowerCase()) || lowerCaseID.includes(stationSearch.toLowerCase());
  });


  return Array.isArray(searchArray)
    ? searchArray
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map((element, index) => {
          const isChecked = index === 0; // Set the first element as checked
  
          return (
            <div className="flex items-center" key={"df_4_" + index}>
              <input
                id={element}
                aria-describedby="stationName"
                type="radio"
                name={"stations"}
                className={`checkbox-` + level + "  text-blue-600 bg-gray-100 border-gray-300"}
                defaultChecked={isChecked} // Set the checked attribute
              />
              <label
                htmlFor="default-checkbox"
                className="text-mobile lg:text-sm pl-2 truncate tooltip collectionOfStations"
                title={"Name : " + element + " || " + " ID : " + getStationId(userFilter, element)}
              >
                {element}
              </label>
            </div>
          );
        })
    : null;
  }