import { useEffect } from "react";

const RenderCustomizedLabel = (props: any) => {

    const { x, y, width, height, value, yAxis } = props;
    const radius = 10;
    const offsetY =  height - 5; // Adjust this value to move the label higher
    if (value) {
      console.log(value)
      return (
        <g className="" key={"circle_"+x}>
          <circle
          className=""
            cx={x + width / 2}
            cy={y - radius + offsetY} // Apply offsetY here
            r={radius}
            fill="#8884d8"
          />
          <text
            x={x + width / 2}
            y={y - radius + offsetY} // Apply offsetY here
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {"T"}
          </text>
        </g>
      );
    }
  
    return null;
  };

  export default RenderCustomizedLabel;