export function latestMessageTime(time: number) {
  if (!time) return " ";
  let fixedTime = time > 1000000000000 ? time : time * 1000;
  fixedTime += 60 * 60 * 1000;
  const currentTime = Date.now();
  const today = new Date(currentTime);

  let day = new Intl.DateTimeFormat(["ban", "id"])
    .format(fixedTime)
    .split("/")
    .reverse();

  let currentDay = new Intl.DateTimeFormat(["ban", "id"])
    .format(today)
    .split("/")
    .reverse();

  let returnedTime = new Date(fixedTime).toString().split(" ");
  let check = checkTime(day, currentDay);
  if (!check) {
    return returnedTime[4].substring(0, 5);
  }

  if (check === 1) {
    return returnedTime[0];
  } else {
    return new Intl.DateTimeFormat(["ban", "id"]).format(fixedTime);
  }
}

export function checkTime(checkedArray: any[], checkedArray2: any[]) {
  let number1: any;
  let today;

  number1 = checkedArray;
  for (let i = 0; i < checkedArray.length; i++) {
    if (number1[i].length === 1) {
      number1[i] = "0" + checkedArray[i];
    }
  }
  number1 = number1.join("");

  today = checkedArray2;
  for (let i = 0; i < checkedArray2.length; i++) {
    if (today[i].length === 1) {
      today[i] = "0" + checkedArray2[i];
    }
  }
  today = today.join("");

  if (parseInt(number1) + 6 < parseInt(today)) {
    return 2; // 1 week
  } else if (number1 < today) {
    return 1; // this week
  } else {
    return 0; // today
  }
}
