
// export function displayStationsDataCenter(stations: any) {
//   return Array.isArray(stations)
//     ? stations.map((station, index) => {
//         return (
//           <tr
//             className="stay text-center bg-gray-100 border-b border-white"
//             key={"stationDataCenter" + index}
//           >
//             <td className="stay tooltip truncate border-r  border-[#9CDDBE] px-2" title={station.station_id}>
//               {station.station_id}
//             </td>
//             <td className="stay tooltip truncate border-r  border-[#9CDDBE] px-2" title={station.station_name}>
//               {station.station_name}
//             </td>
//             <td className="stay truncate border-r tooltip border-[#9CDDBE] px-2" title={station.raw_data}>
//               {station.raw_data}
//             </td>
//             <td className="stay truncate border-r tooltip border-[#9CDDBE] px-2" title={station.reported_data}>
//               {station.reported_data}
//             </td>
//             <td className="stay truncate border-r tooltip border-[#9CDDBE] px-2" title={station.date}>
//               {station.date}
//             </td>
//             <td className="stay truncate border-r tooltip border-[#9CDDBE] px-2" title={station.basin}>
//               {station.basin}
//             </td>
//             <td className="stay truncate border-r tooltip border-[#9CDDBE] px-2" title={station.district}>
//               {station.district}
//             </td>
//             <td className="stay truncate border-r tooltip border-[#9CDDBE] px-2" title={station.region}>
//               {station.region}
//             </td>
//             <td className="stay tooltip truncate px-2 " title={station.comment}>{station.comment}</td>
//           </tr>
//         );
//       })
//     : null;
// }


import { FixedSizeList as List } from 'react-window';

export function displayStationsDataCenter(stations: any, mmOrm: string) {
  if (!Array.isArray(stations)) {
    return null;
  }

  const Row = ({ index, style } : { index: number, style: React.CSSProperties }) => {
    const station = stations[index];
    const cellStyle: React.CSSProperties = {
      maxWidth: '10rem', // Adjust the maximum width as needed
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap' as 'nowrap',
    };
    return (
      <tr
        className="stay text-center bg-gray-100 border-b border-white w-[100%]"
        key={"stationDataCenter" + index}
        style={style}
      >
           <td className="stay tooltip truncate overflow-hidden border-r  border-[#9CDDBE] px-2 w-40 "style={cellStyle}  title={station.station_id}>
               {station.station_id}
             </td>
             <td className="stay tooltip truncate overflow-hidden border-r  border-[#9CDDBE] px-2 w-40 "style={cellStyle}  title={station.station_name}>
               {station.station_name}
             </td>
             {
                mmOrm === "Temperature" && <td className="stay  tooltip truncate overflow-hidden px-2 border-r  border-[#9CDDBE] w-40" style={cellStyle} title={station.reading_type}>{station.reading_type}</td>
             }
             <td className="stay truncate overflow-hidden border-r tooltip border-[#9CDDBE] px-2 w-40"style={cellStyle}  title={station.raw_data}>
               {station.raw_data}
             </td>
             <td className="stay truncate overflow-hidden border-r tooltip border-[#9CDDBE] px-2 w-40" style={cellStyle} title={station.reported_data}>
               {station.reported_data}
             </td>
{   mmOrm ==="River Level" &&   <td className="stay truncate overflow-hidden border-r tooltip border-[#9CDDBE] px-2 w-40" style={cellStyle} title={station.daily_average}>
               {station.daily_average}
             </td>}
             <td className="stay truncate overflow-hidden border-r tooltip border-[#9CDDBE] px-2 w-40"style={cellStyle}  title={station.date}>
               {station.date}
             </td>
             <td className="stay truncate overflow-hidden border-r tooltip border-[#9CDDBE] px-2 w-40" style={cellStyle} title={station.basin}>
               {station.basin}
             </td>
             <td className="stay truncate overflow-hidden border-r tooltip border-[#9CDDBE] px-2 w-40" style={cellStyle} title={station.district}>
               {station.district}
             </td>
             <td className="stay truncate overflow-hidden border-r tooltip border-[#9CDDBE] px-2 w-40" style={cellStyle} title={station.region}>
               {station.region}
             </td>
             <td className="stay  tooltip truncate overflow-hidden px-2  w-40" style={cellStyle} title={station.comment}>{station.comment}</td>


      </tr>
    );
  };


  let symbol = "(mm)"

  switch(mmOrm){
    case "Temperature":
      symbol = "(°C)";
      break;
      case "Rainfall":
        symbol = "(mm)";
        break;
        case "River Level":
          symbol = "(m)";
          break;
  }

  return (
    <table cellSpacing="0" cellPadding="0" className="w-full h-full table-fixed" id="DataCenterTable">
    <tbody className="max-h-full w-full h-full overflow-auto overflow-x-scroll table-fixed">
              <tr className="bg-white">
          <th className="w-40">Station ID</th>
          <th className="w-40">Station Name</th>
          {
                mmOrm === "Temperature" && <th className="w-40 tooltip truncate" title={"Measurement Type"}>Measurement Type</th>
             }
          <th className="w-40">Raw Data</th>
          <th className="w-40">Reported Data {symbol} </th>
          { mmOrm ==="River Level" && <th className="w-40">Daily Average {symbol} </th>}

          <th className="w-40">Date</th>
          <th className="w-40">Basin</th>
          <th className="w-40">District</th>
          <th className="w-40">Region</th>
          <th className="w-40">Comment</th>
        </tr>
    <List
      height={500} // Set the height of the visible portion of the table
      itemCount={stations.length} // Total number of rows in the table
      itemSize={30} // Set the height of each row
      width={mmOrm === "Rainfall" ? '900%' : "1000%"}
      className='w-full'
    >
      {Row}
    </List>
    </tbody>
    </table>
  );
}