import { blackScreen } from "../chat/misc/userFunction";

function displayReaders(readers:any){
    if (readers.length === 0) return;

    return Array.isArray(readers)
      ? readers.map((reader, index) => {

          return (
            `
            <div class="w-1/2" key={"readersInStationArrayElement-"+index}>
            <div class= "h-24 pt-2 pointer-events-none pr-2" id="">
                      <div class="pl-2 flex overflow-hidden">
                        <div class="h-12 w-12 rounded-full pointer-events-none flex-none">
                          <img
                            class="pointer-events-none"
                            src="${"male_profilePicture"}"
                          ></img>
                        </div>
        
                        <div class="flex flex-auto h-12 p-2 pl-0 items-center pointer-events-none relative">
                          <div class="flex items-center pointer-events-none w-5/6 truncate">
                            <div class="pl-2 pointer-events-none flex flex-col truncate">
                              <span class="pointer-events-none truncate flex">
                                ${reader.first_name + " " + reader.last_name}
                              </span>
                              <p class="text-slate-500">
                                ${reader.role}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
        
                </div>
        
            </div>   
            `
          );
        }).join('')
      : null;
}

export function viewReaders(readers: any) {

  blackScreen();
  const parentDiv = document.getElementById("blackScreen");
  const tempDiv = document.createElement("div");
  tempDiv.classList.add("w-full", "lg:w-1/2");
  tempDiv.innerHTML = `
    <div class="h-screen w-full lg:h-3/4 bg-white stay lg:rounded-xl overflow-y-auto pb-24 lg:pb-8" >
    <div class="flex flex-col w-full pt-5 justify-center items-center stay">
    <div class="flex justify-end w-full text-black px-2 stay">

      <button class="text-2xl hover:bg-gray-100 rounded-full h-9 w-9"
      >
        X
      </button>
    </div>

    <div class="w-full px-5 flex flex-wrap justify-start stay">

    ${displayReaders(readers)}

    </div>
    </div>

  </div>
  </div>
      `;
  parentDiv?.appendChild(tempDiv);
}
