import { latestMessageTime } from "./Time"

export function displaySocketLatestMessage(number:string, text:string, time_stamp: number){
    if(!document.getElementById("userText-"+number)) return
          let userText = document.getElementById("userText-"+number) as HTMLElement
          let userTime = document.getElementById("latestMessageTime-"+number) as HTMLElement
          let userUnread = document.getElementById("user-"+number+"-parentDiv") as HTMLElement
           userText.innerText = text;
           userTime.innerText = latestMessageTime(time_stamp)
           userUnread?.classList.add("bg-green-50")

  }
  
  export function unDisplaySocketLatestMessage(number:string){
    if(!document.getElementById("userText-"+number)) return
    let userUnread = document.getElementById("user-"+number+"-parentDiv") as HTMLElement
    userUnread?.classList.remove("bg-green-50")

  }
  