export default function ChatLanding() {
  return (
    <div className="flex-auto bg-white rounded-xl flex flex-col overflow-hidden">
      <div className="bg-white h-full p-4">
        <div className="bg-white h-full flex flex-col items-center justify-center">
          <img
            src={
              "https://static.wixstatic.com/media/a2eddc_fe73a659d6354f3db5fab23638f7041b~mv2.png/v1/fill/w_228,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Water-in-Sight-logo.png"
            }
          />
          <div>
            <p>Select a chat</p>
          </div>
        </div>
      </div>
    </div>
  );
}
