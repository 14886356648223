import Dashboard, { displayCountries, displayCountriesDropDown, removeCountries } from "./Dashboard";
import plusIcon from "./assets/plusIcon.svg";
import searchIcon from "./assets/searchIcon.svg";

import { useEffect, useState } from "react";
import { displayUsers } from "./components/chat/DisplayUsers";
import { useNavigate } from "react-router-dom";
import SelectedChat from "./components/chat/SelectedChat";
import { createContact } from "./components/chat/userFunctions/CreateUser";
import ChatLanding from "./components/chat/ChatLanding";
import { url } from "./components/variables";
import { displaySocketLatestMessage } from "./components/chat/SocketFunctions";
import MobileDashboard from "./MobileDashboard";
import { logoutMobile } from "./logoutMobile";
import profilePic from "./assets/profilePic.svg";
import downloadIconGreen from "./assets/downloadIconGreen.svg";
import { downloadCsvFromArrayOfObjects } from "./downloadCSV";

export default function ChatApp(params:any) {
  const [windowSizeBolean, setSizeBolean] = useState(window.innerWidth >= 1024);
  let searchBar = document.getElementById("searchBar") as HTMLInputElement
  let searchBarValue = searchBar?.value
  const [search, setSearch] = useState(searchBarValue ? searchBarValue : " ");
  const [searchArray, setSearchArray] = useState<any[]>([]);
  const navigate = useNavigate();
  
  useEffect(() => {

    if (search.match(/^ *$/)) {
      if(searchArray.length !== params.users.length) {
        setSearchArray(params.users)
      }

    } else {
      setSearchArray(params.users.filter((user:any) =>
      (user.first_name + user.last_name)
        .toLowerCase()
        .includes(search.toLowerCase().replace(/\s/g, ""))
    ));
    }
  }, [search,params.users]);

  function ontop(event:any){
    let data = JSON.parse(event.data)
    let testingArray = search.match(/^ *$/) ? params.users : params.users.filter((user:any) =>
    (user.first_name + user.last_name)
      .toLowerCase()
      .includes(search.toLowerCase().replace(/\s/g, ""))
  )
    for(let i = 0; i < testingArray.length ;i++){
      if(data.tel_number === testingArray[i].telphone_number){
        if(i === 0){
          if(window.location.href === url+"wischat/"+data.tel_number) return
          testingArray[0].last_message_time = data.time_stamp
          displaySocketLatestMessage(data.tel_number, data.message, data.time_stamp)
          return
        }
        let tempArr = [...testingArray]
        let tempPos = testingArray[i]

        if(window.location.href !== url+"wischat/"+data.tel_number){
          tempPos.is_read = false
        }

        tempPos.last_message = data.message
        tempPos.last_message_time = data.time_stamp

        setSearchArray(tempArr)
      }
    }

  }

  const socket = new WebSocket("wss://waterinsights.kedra.info:8000/ws/222");

  useEffect(() => {
    if(socket){
    socket.onmessage = (event) => {
      ontop(event)
    };

    socket.onerror = (event) => {
      console.log("WebSocket error received: ", event);
  }
}
}, [window.location.href,params.users, search]);

  useEffect(() => {
    window.addEventListener("resize", function handleResize() {
      setSizeBolean(this.window.innerWidth >= 1024);
    });
  }, []);

  useEffect(() => {
    window.onclick = function (e) {
      let checkIfHidden = document.getElementById("countryDropdown") as HTMLElement
      if(!checkIfHidden.classList.contains("hidden")){
        
        const target = e.target as Element;
            if (!target.classList.contains("stay")) {
              removeCountries();
            }
      }
    };
  }, []);

  return (
    <>
      <div
        className="fixed w-full h-screen bg-black bg-opacity-50 z-10 justify-center items-center hidden stay"
        id="blackScreen"
      ></div>
      <div className="bg-gradient-to-b from-[#00A2D8] to-[#88DFBC] w-full h-screen opacity-[0.23] fixed z-0"></div>
      <div className="h-screen w-full p-1 lg:p-6 ">
        <div className="w-full min-h-full h-full relative border-2 border-white rounded-xl p-1 lg:p-6 bg-white bg-opacity-40 flex">
          {windowSizeBolean ? <Dashboard /> : <MobileDashboard/>}

          <div className="w-full lg:w-9/12 xl:w-10/12 h-full min-h-full flex gap-2 xl:gap-6 ">
            <div className=" xl:w-[300px] w-full lg:w-[275px] h-full lg:ml-2 xl:ml-6 flex flex-col bg-white rounded-xl">
              
              

              <div className="h-12 flex w-full">
                <div className="w-1/5 bg-[#00BB59] rounded-tl-xl"></div>
                <div className="flex-auto flex justify-center">
                  <div className="w-11/12 border-b border-[#A3E5F6] flex text-2xl justify-between items-center">
                    <div className="w-auto lg:w-full flex gap-2 lg:gap-0 items-center  lg:justify-between">

                    <div className="flex gap-2">
                      <p className="text-[#00A8DF]">{params.users.length}</p>
                      <p>Users</p>
                    </div>
                    <div className="flex gap-3">
                    <img src={downloadIconGreen} className="w-[20px] h-[20px] " onClick={()=>downloadCsvFromArrayOfObjects(params.users, "Users")}></img>
                    <img src={plusIcon} className="w-[20px] h-[20px]" onClick={()=>createContact(params.stations)}></img>
                    </div>
                    </div>

                    <div className="w-1/2 lg:hidden flex justify-end items-center mr-2 gap-4">             
                    <div className=" stay relative"> <p className="hover:bg-gray-200 p-2 stay -rotate-90 text-center" onClick={()=>displayCountriesDropDown()}>{"<"}</p> 
                    <div className=" absolute top-12 right-0 w-48 bg-white border-2 border-black flex flex-col items-start pt-4 gap-4 overflow-hidden pb-8 z-[9999] stay text-sm hidden" id="countryDropdown">
                  {displayCountries()}        
            </div>
                    </div> 
     
 <img src={profilePic} className="w-[27px] h-[27px] " onClick={()=>logoutMobile()}></img></div>
                  </div>
                </div>
              </div>

              <div className="w-full flex items-center">
              <div className="w-1/5 bg-[#00BB59] h-full"></div>
              <div className="w-4/5 flex  items-center border-[#A3E5F6] border-b">
              <input
                    className="py-2 px-2 w-10/12 focus:outline-none  bg-white "
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="search..."
                    id= "searchBar"
                    value={search}
                  />

                  <div className="w-2/12 flex justify-end pr-3">
                    <img src={searchIcon} width="20px" height={"20px"} />
                  </div>
              </div>

                </div>

              

              <div className="flex-auto flex w-full flex-col overflow-y-scroll  mb-16 lg:mb-0">
                <div className="h-0">
                  {params.users.length ? displayUsers(searchArray, search, navigate) : null}
                </div>
              </div>
              </div>

      

              {windowSizeBolean ? params.user !== undefined ? <SelectedChat user = {params.user} stations={params.stations}/> : <ChatLanding/> : <p></p>}
            
          </div>
        </div>
      </div>
    </>
  );
}
