import React from "react";

export function displayDashboardUsers(users: any) {
    if (users.length === 0) return;
    const uniqueArr = [...new Set(users)];
    return Array.isArray(uniqueArr)
    ? uniqueArr.sort((a:any, b:any) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)).map((user:any, index) => {
        return (
          <div className="flex items-center truncate" key={"dfu_" + index}>
            <input id="d" type="checkbox"  className="exclude" disabled/>
            <label
              htmlFor="def"
              className="text-sm pl-2 truncate tooltip"
              title={user}
            >
           
              {user}
            </label>
          </div>
        )
      })
    : null;
}
