import { handleLogOut } from "./Dashboard";
import { getCookie } from "./components/Api/Cookies";
import { blackScreen, removeBlackScreen } from "./components/chat/misc/userFunction";


export function logoutMobile(){
    blackScreen();
    const parentDiv = document.getElementById("blackScreen");
    const tempDiv = document.createElement("div");
    tempDiv.classList.add("w-full","lg:w-1/3")
    tempDiv.innerHTML = `
    <div class="h-auto w-full lg:h-3/4 bg-white stay lg:rounded-xl overflow-y-auto pb-24 lg:pb-8" >
    <div class="flex justify-end w-full text-black pr-2 stay">
    <button class="text-2xl hover:bg-gray-100 rounded-full h-9 w-9"
    id="closeButton"
    >
      X
    </button>
  </div>
    <div class="stay text-center"> Profile </div>
    <div class=" w-full px-2 stay">
    <div class="stay bg-white mt-2 flex flex-col rounded-md">
      <div class="stay text-center">
        Logged in as ${getCookie("username")}
      </div>
    </div>
    </div>
    <div class="w-full text-center text-red-600 hidden stay" id="errorTextDeleteConfirmation"> 
    Please type exactly DELETE to delete this user!
    </div>
    <div class="w-full flex">
    <div class="w-full p-1">
    <div class="stay w-full bg-white  mt-2 flex rounded-md">
      <button class="stay w-full bg-red-400 rounded-md  hover:bg-red-500 p-2" id="logoutButton" type="button">Log out </button>
    </div>
    </div>
  
  </div>
  </div>
    </div>
      `;
    parentDiv?.appendChild(tempDiv);



    document
    .getElementById("logoutButton")
    ?.addEventListener("click", handleLogOut);
    
    document.getElementById("closeButton")?.addEventListener('click', function handeClick(){
        removeBlackScreen()
      } )


}