import dashboardIcon from "./assets/dashboardIcon.svg";
import messageIcon from "./assets/messageIcon.svg";
import locationIcon from "./assets/locationIcon.svg";
import SelectedStations from "./assets/SelectedStations.svg";
import selectedMessages from "./assets/selectedMessages.svg";

import arrowDown from "./assets/arrowDown.svg";
import profilePic from "./assets/profilePic.svg";
import { url } from "./components/variables";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { cookieExsist } from "./components/Api/Cookies";


export default function MobileDashboard(){
    const navigate = useNavigate()

    useEffect(()=>{
        if(!cookieExsist("acessToken")){
          navigate("login")
        }
      })
    return(
        <div className="absolute px-1 lg:hidden  bottom-1 left-0 w-full h-16 z-[9] ">
            <div className="flex bg-white w-full h-16 rounded-b-xl">
            <div className={`w-1/3 flex flex-col justify-center items-center text-xs gap-2 ${window.location.href === url + "home"? "bg-[#9CDDBE] bg-opacity-20 border-t-2 border-[#5AC590]" :  ""}`} onClick={()=>navigate("/home")}> <img src={window.location.href === url + "home" ? dashboardIcon : dashboardIcon} className="w-[21px] h-[26px]"/><p>Data Center</p> </div>
            <div className={`w-1/3 flex flex-col justify-center items-center text-xs gap-2 ${window.location.href === url + "wischat"? "bg-[#9CDDBE] bg-opacity-20 border-t-2 border-[#5AC590]" :  ""}`} onClick={()=>navigate("/wischat")} > <img src={window.location.href === url + "wischat" ? selectedMessages : messageIcon} className="w-[21px] h-[26px]"/> <p>Chat</p> </div>
            <div className={`w-1/3 flex flex-col justify-center items-center text-xs gap-2 ${window.location.href === url + "stations"? "bg-[#9CDDBE] bg-opacity-20 border-t-2 border-[#5AC590]" :  ""}`} onClick={()=>navigate("/stations")}> <img src={window.location.href === url + "stations" ? SelectedStations : locationIcon } className="w-[17px] h-[26px]"/> <p>Stations</p> </div>
            </div>
        </div>
    )
}