import { deleteUser, updateContact } from "../../Api/APIModules";
import { female_profilePicture, male_profilePicture } from "../../variables";
import { confirmDelete } from "../misc/ConfirmDelete";
import { blackScreen, removeBlackScreen } from "../misc/userFunction";


function displayStationOptionsViewProfile(stations: any, user:any){
  return Array.isArray(stations)
  ? stations.map((station, index) => {
        return (
          `
          <option value="${station.station_id}"  ${station.station_id === user.station_id ? "selected" : null}> ${station.station_id} </option>
          `
        );
      }).join('')
    : null;
}

export function viewProfile(user: any, stations:any) {

  blackScreen();
  const parentDiv = document.getElementById("blackScreen");
  const tempDiv = document.createElement("div");
  tempDiv.classList.add("lg:w-1/2", "h-screen");
  tempDiv.innerHTML = `
    <div class=" w-full h-full lg:h-[90%] lg:mt-[5%] bg-white stay lg:rounded-xl pb-24 lg:py-8 overflow-y-auto" >
    <div class="flex flex-col w-full justify-center items-center stay ">
    <div class="flex justify-end w-full text-black pr-2 pt-1 stay">
      <button class="text-2xl hover:bg-gray-100 rounded-full h-9 w-9"
      id="closeButton"
      >
        X
      </button>
    </div>
  
    <img class="h-24 w-24 lg:h-48 lg:w-48 rounded-full " id="profilePicture_viewProfile" src="${user.gender === "Male"? male_profilePicture : female_profilePicture}" />

    <form class="w-full px-5 flex flex-wrap justify-center stay" id="viewPorifleForm">
    <div class="lg:w-1/2 w-full px-2 stay">
    
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">First Name:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="firstName_viewProfile"
          value="${user.first_name}"
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
    <div class="stay text-gray-400 pl-2 pt-2 text-sm">Last Name:</div>
    <div class="stay">
      <input
        class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
        type="text"
        id="lastName_viewProfile"
        value="${user.last_name}"
        
      />
    </div>
  </div>
  </div>
   
  <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Phone Number:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="number_viewProfile"
          value="${user.telphone_number}"
          required
          disabled = "true"
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Role:</div>
      <div class="stay">
        <select class="stay w-full pl-2 py-2 rounded-md bg-white focus:outline-none" id="qualification_viewProfile" >
          <option value="No Role" > Select Role </option>
          <option value="Gauge Reader" ${user.role === "Gauge Reader" ? "selected" : null}> Gauge Reader </option>
          <option value="Observer" ${user.role === "Observer" ? "selected" : null}> Observer </option>
          <option value="Operational Manager" ${user.role === "Operational Manager" ? "selected" : null}> Operational Manager </option>
          <option value="CTO" ${user.role === "CTO" ? "selected" : null}> CTO </option>
          </select>
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Type of phone:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="typeOfPhone_viewProfile"
          value="${user.type_of_phone}"
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Role in community:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="roleInCommunity_viewProfile"
          value="${user.role_in_community}"
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Date of Birth:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="dateOfBirth_viewProfile"
          value="${user.date_of_birth}"
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Gender:</div>
      <div class="stay">
        <select
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
          id="gender_viewProfile"
          
        />
        <option value=""> Select gender </option>
        <option value="Male" ${user.gender === "Male" ? "selected" : null}> Male </option>
        <option value="Female"${user.gender === "Female" ? "selected" : null}> Female </option>
        </select>
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">District:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="district_viewProfile"
          value="${user.district}"
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Town/Village:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="town_viewProfile"
          value="${user.town_or_village}"
          
        />
      </div>
    </div>
    </div>
  
      
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Station Name:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="stationName_viewProfile"
          value="${user.station_name}"

        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Station ID:</div>
      <div class="stay">
        <select
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
          id="stationID_viewProfile"
          
        />
        <option value=""> Select Station </option>
        ${displayStationOptionsViewProfile(stations, user)}
        </select>
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Time to travel to station:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="timeToTravel_viewProfile"
          value="${user.time_travel_to_station}"
          
        />
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">River Basin:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="riverBasin_viewProfile"
          value="${user.river_basin}"
          
        />
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">National ID number:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="nationalIDNumber_viewProfile"
          value="${user.national_id_number}"
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Livelihood:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="livelihood_viewProfile"
          value="${user.livelihood}"
          
        />
      </div>
    </div>
    </div>

    <div class="w-full flex">
    <div class="w-1/2 p-1">
    <div class="stay w-full bg-white  mt-2 flex rounded-md">
      <button class="stay w-full bg-red-400 rounded-md  hover:bg-red-500 p-2" id="deleteContactButton" type="button">Delete User</button>
    </div>
    </div>
  
    <div class="w-1/2 p-1">
    <div class="stay w-full bg-white border border-black mt-2 flex rounded-md">
    <button class="stay w-full hover:bg-gray-100 rounded-md p-2" type="submit">Update User</button>
    </div>
  </div>
  </div>
    </form>
  </div>
  </div>
      `;
  parentDiv?.appendChild(tempDiv);


  const firstName = document.getElementById("firstName_viewProfile") as HTMLInputElement;
  const lastName = document.getElementById("lastName_viewProfile") as HTMLInputElement;
  const number = document.getElementById("number_viewProfile") as HTMLInputElement;
  const qualification = document.getElementById("qualification_viewProfile") as HTMLInputElement;
  const typeOfPhone = document.getElementById("typeOfPhone_viewProfile") as HTMLInputElement;
  const roleInCommunity = document.getElementById("roleInCommunity_viewProfile") as HTMLInputElement;
  const dateOfBirth = document.getElementById("dateOfBirth_viewProfile") as HTMLInputElement;
  const gender = document.getElementById("gender_viewProfile") as HTMLInputElement;
  const district = document.getElementById("district_viewProfile") as HTMLInputElement;
  const town = document.getElementById("town_viewProfile") as HTMLInputElement;
  const stationID = document.getElementById("stationID_viewProfile") as HTMLInputElement;
  const stationName = document.getElementById("stationName_viewProfile") as HTMLInputElement;
  const timeToTravel = document.getElementById("timeToTravel_viewProfile") as HTMLInputElement;
  const riverBasin = document.getElementById("riverBasin_viewProfile") as HTMLInputElement;
  const nationalIDNumber = document.getElementById("nationalIDNumber_viewProfile") as HTMLInputElement;
  const livelihood = document.getElementById("livelihood_viewProfile") as HTMLInputElement;


  document.getElementById("closeButton")?.addEventListener('click', function handeClick(){
    removeBlackScreen()
  } )


  document
    .getElementById("deleteContactButton")
    ?.addEventListener("click", function handeClick() {
      removeBlackScreen()
      confirmDelete(deleteUser, number.value);
    });

  document
    .getElementById("viewPorifleForm")
    ?.addEventListener("submit", function handleSubmit(event) {
      event.preventDefault();


      let argumentObject = {
        "first_name": firstName?.value ,
        "last_name": lastName?.value,
        "role": qualification?.value,
        "type_of_phone": typeOfPhone?.value,
        "role_in_community": roleInCommunity?.value,
        "date_of_birth": dateOfBirth?.value,
        "gender": gender?.value,
        "station_id": stationID?.value,
        "station_name": stationName?.value,
        "town_or_village": town?.value,
        "district": district?.value,
        "river_basin": riverBasin?.value,
        "time_travel_to_station": timeToTravel?.value,
        "national_id_number": nationalIDNumber?.value,
        "livelihood" : livelihood?.value,
        "profile_picture" : " ",
      }

      updateContact(
        user.station_id,
        argumentObject,
        number.value.replace(/\s/g, "")
        );

    });
}
