import { cookieExsist, getCookie } from "../../Api/Cookies";

export function selectedCountry(){
    if(cookieExsist("selectedCountry")){
        return getCookie("selectedCountry")
    }
    
    let x = getCookie("countries")
    let array = x === null ? null : JSON.parse(x)
    return(Array.isArray(array) ? array[0] : "null")
}