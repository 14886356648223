import React, { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import Stats from "./Stats";
import MobileDashboard from "./MobileDashboard";

function App(params:any) {
  const [windowSizeBolean, setSizeBolean] = useState(window.innerWidth >= 1024);

  {
    /*
   Listen to windows size change
   set window size bolean for responsive purposes
  */
  }

  useEffect(() => {
    window.addEventListener("resize", function handleResize() {
      setSizeBolean(this.window.innerWidth >= 1024);
    });
  }, []);

  return (
    <>
      <div className="bg-gradient-to-b from-[#00A2D8] to-[#88DFBC] w-full h-screen opacity-[0.23] fixed z-0"></div>
      <div
        className="fixed w-full h-screen bg-black bg-opacity-50 z-10 justify-center items-center hidden stay"
        id="blackScreen"
      ></div>
      <div className="h-screen w-full p-1 lg:p-6 ">
        <div className="w-full h-full relative border-2 border-white rounded-xl p-1 lg:p-6 bg-white bg-opacity-40 flex">
          { windowSizeBolean ? <Dashboard /> : <MobileDashboard/> } 
          <Stats users={params.users} />
        </div>
      </div>
    </>
  );
}


export default App;
