import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Login from "./Login";

import { useState, useEffect } from "react";
import axios from "axios";

import { cookieExsist } from "./components/Api/Cookies";
import { getStations, getUsers } from "./components/Api/APIModules";
import { url } from "./components/variables";
import ChatApp from "./ChatApp";
import Stations from "./Stations";
import App from "./App";
import LoadingScreen from "./components/LoadingScreen";
import SelectedChatApp from "./SelectedChatApp";
import { selectedCountry } from "./components/chat/misc/SelectedCountry";
function Router() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (cookieExsist("acessToken")) {
      getUsers(cancelToken, setUsers, selectedCountry());
    }
    return () => {
      cancelToken.cancel();
    };
  }, []);

  const [stations, setStations] = useState([]);

  useEffect(() => {
    if(cookieExsist("acessToken")){
    const cancelToken = axios.CancelToken.source();
    if (cookieExsist("acessToken")) {
      getStations(cancelToken, setStations, selectedCountry());
    }
    return () => {
      cancelToken.cancel();
    };
  }}, []);

  useEffect(() => {
    if(cookieExsist("acessToken")){
    if (window.location.href !== url + "login") {
      if (!cookieExsist("acessToken")) {
        window.location.replace(url + "login");
      }
    }
  }}, [cookieExsist("acessToken")]);

  function createLinks(users: any[]) {
    if (users.length === 0) return;
    const links = users.map((user, index, array) => {
 
      return (
        <Route
          path={"/wischat/" + user.telphone_number}
          element={
            <SelectedChatApp users={array} stations={stations} user={user} />
          }
          key={index}
        />
      );
    });

    return links;
  }

  return (
    <BrowserRouter>
      <Routes>
        <>{  cookieExsist("acessToken") ? createLinks(users) : null}</>
        <Route path="*" element={<LoadingScreen />}>
          {" "}
        </Route>
        <Route path="/" element={<Login />}>
          {" "}
        </Route>
        <Route path="/home" element={<App users={users} />} />
        <Route path="/stations" element={<Stations stations={stations} />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/wischat"
          element={<ChatApp users={users} stations={stations} />}
          
        />
        <Route path="/loadingpage" element={<LoadingScreen />} />

      </Routes>
    </BrowserRouter>
  );
}

export default Router;

// # User login and authentication

// # See all messages sent/recieved in real time.

// # All users have the same view.

// # Show user that sent message

// # Send message to a group of users

// # Show unread messages - in app notification
