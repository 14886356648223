import { blackScreen, removeBlackScreen } from "./userFunction";


export function confirmDelete(passedFunction:any, functionArg:any ){
    blackScreen();
    const parentDiv = document.getElementById("blackScreen");
    const tempDiv = document.createElement("div");
    tempDiv.classList.add("w-full","lg:w-1/3")
    tempDiv.innerHTML = `
    <div class="h-auto w-full lg:h-3/4 bg-white stay lg:rounded-xl overflow-y-auto pb-24 lg:pb-8" >
    <div class="flex justify-end w-full text-black pr-2 stay">
    <button class="text-2xl hover:bg-gray-100 rounded-full h-9 w-9"
    id="closeButton"
    >
      X
    </button>
  </div>
    <div class="stay text-center"> Type DELETE to confirm </div>
    <div class=" w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="confirmDelete"
          value=""
        />
      </div>
    </div>
    </div>
    <div class="w-full text-center text-red-600 hidden stay" id="errorTextDeleteConfirmation"> 
    Please type exactly DELETE to delete this user!
    </div>
    <div class="w-full flex">
    <div class="w-full p-1">
    <div class="stay w-full bg-white  mt-2 flex rounded-md">
      <button class="stay w-full bg-red-400 rounded-md  hover:bg-red-500 p-2" id="confirmDeleteButton" type="button">Delete User</button>
    </div>
    </div>
  
  </div>
  </div>
    </div>
      `;
    parentDiv?.appendChild(tempDiv);

    document.getElementById("closeButton")?.addEventListener('click', function handeClick(){
      removeBlackScreen()
    } )

    document
    .getElementById("confirmDeleteButton")
    ?.addEventListener("click", function handeClick() {
       let confirmValue = document.getElementById("confirmDelete") as HTMLInputElement;
       let errorMessage = document.getElementById("errorTextDeleteConfirmation")
        if(confirmValue.value === "DELETE"){
            passedFunction(functionArg);
        }else{
            console.log(errorMessage?.classList)
            errorMessage?.classList.remove("hidden")
        }
    });


}