export function DisplayAllMessages(msg: any, profile_picture: string) {

  if (msg.length === 0) return null;

  return Array.isArray(msg)
    ? msg.map((msgs, index, array) => {

        if (msgs.source === "App")
          return (
            <div
              className="col-start-1 col-end-13  w-full text-chat-mobile"
              key={"message" + index}
            >
              <div className="col-start-6 col-end-13 p-3 rounded-lg">
                <div className="flex items-center justify-start flex-row-reverse">
                  <div className="flex items-center justify-center h-12 w-12 rounded-full bg-gray-400 flex-shrink-0">
                    <img src={profile_picture}></img>
                  </div>
                  <div className="relative mr-3 text-chat-mobile lg:text-sm bg-[#E1F7FD] py-2 px-4 shadow rounded-xl">
                    <div className="flex flex-col">
                      <p className="break-words max-w-2xl">{msgs.message}</p>
                      <div className="text-end text-chat-mobile lg:text-xs text-gray-500 flex gap-2">
                        {ifTime(msgs.time_stamp)} <span className="flex">Sender: {" " +msgs.sender} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        else
          return (
            <div className="col-start-1 col-end-13  w-full" key={"message" + index}>
            <div className="col-start-1 col-end-8 w-full p-3 rounded-lg">
              <div className="flex flex-row items-end">
                <div className="flex items-center justify-center h-12 w-12 rounded-full bg-gray-400 flex-shrink-0">
                  <img src={profile_picture}></img>
                </div>
                <div className="relative ml-3 text-chat-mobile lg:text-sm bg-gray-200 py-2 px-4 shadow rounded-xl">
                  <div className="flex flex-col ">
                    <p className="break-words max-w-2xl">
                    {msgs.message}
                    </p>

                    <div className="text-end text-chat-mobile lg:text-xs text-gray-500">
                      {ifTime(msgs.time_stamp)
                     
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          );
      })
    : null;
}


function ifTime( date2: number) {
  // Time fixed to always be in ms
  let fixedTime2 =
    date2 > 1000000000000
      ? date2 - 60 * 2 * 1000 * 60
      : date2 * 1000 - 60 * 2 * 1000 * 60;
  const currentTime = Date.now();
  const today = new Date(currentTime);
  let time = new Date(fixedTime2);

  let returnedTime = time.toString().split(" ");

  let day = new Intl.DateTimeFormat(["ban", "id"])
    .format(time)
    .split("/")
    .reverse();

  let currentDay = new Intl.DateTimeFormat(["ban", "id"])
    .format(today)
    .split("/")
    .reverse();
    console.log(day, currentDay , checkTime(day, currentDay) )

  if (checkTime(day, currentDay)) {
    return (
      <p className="flex">
          {returnedTime[0]} {returnedTime[1]} {returnedTime[2]}{" "}
          {returnedTime[3]} {returnedTime[4].substring(0, 5)} {"(UTC+2)"}
      </p>
    );
  }else{
    
    return (
      <p className="flex">
          Today @ {returnedTime[4].substring(0, 5)} {"(UTC+2)"}
      </p>
    );
  }

}

function firstTextTime(time: number) {
  let date = new Date(
    time > 1000000000000
      ? time - 60 * 2 * 1000 * 60
      : time * 1000 - 60 * 2 * 1000 * 60
  );
  let returnedTime = date.toString().split(" ");
  return (
    <p className="flex">
        {" "}
        {returnedTime[0]} {returnedTime[1]} {returnedTime[2]} {returnedTime[3]}{" "}
        {returnedTime[4].substring(0, 5)} {"(UTC+2)"}{" "}
    </p>
  );
}

export function checkTime(checkedArray: any[], checkedArray2: any[]) {
  let messageYear = parseInt(checkedArray[0])
  let thisYear = parseInt(checkedArray2[0])

  let messageMonth = parseInt(checkedArray[1])
  let thisMonth = parseInt(checkedArray2[1])

  let messageDay = parseInt(checkedArray[2])
  let thisDay = parseInt(checkedArray2[2])


  if(messageYear  === thisYear){
      if(messageMonth === thisMonth ){

          return messageDay !== thisDay
      }
      return true
  }
  return true


}

// {
// message: "timetest",
// read: true,
// sender: "WIS",
// source: "App",
// tel_number: "Demo",
// time_stamp: 1673021353
// }
