import { createContactBE } from "../../Api/APIModules";
import { male_profilePicture } from "../../variables";
import { blackScreen, removeBlackScreen } from "../misc/userFunction";


function displayStationOptions(stations: any){
  
  return Array.isArray(stations)
    ? stations.map((station, index) => {
        return (
          `
          <option value="${station.station_id}"> ${station.station_id} </option>
          `
        );
      }).join('')
    : null;
}

export function createContact(stations: any) {

    blackScreen();
    const parentDiv = document.getElementById("blackScreen");
    const tempDiv = document.createElement("div");
    tempDiv.classList.add("w-full","lg:w-1/2", "h-screen")
    tempDiv.innerHTML = `
    <div class=" w-full  h-full lg:h-[90%] lg:mt-[5%] overflow-y-auto bg-white stay lg:rounded-xl pb-24 lg:pb-8" >
    <div class="flex flex-col w-full pt-5 justify-center items-center stay">
    <div class="flex justify-end w-full text-black pr-2 stay">
      <button class="text-2xl hover:bg-gray-100 rounded-full h-9 w-9"
      id="closeButton"
      >
        X
      </button>
    </div>
  
    <img class="h-24 w-24 lg:h-48 lg:w-48 rounded-full " id="profilePicture_viewProfile" src="${male_profilePicture}" />
  
      <div class="stay bg-white h-full opacity-50 absolute w-full justify-center items-center hidden group-hover/item:flex"></div>
      <p class="stay pt-2 text-xl text-black opacity-100 hidden group-hover/item:flex">
        Change
      </p>
    </div>
    <form class="w-full px-5 flex flex-wrap justify-center stay" id="createForm" autoComplete="off">
    <div class="lg:w-1/2 w-full px-2 stay">

    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">First Name:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="firstName"
          value=""
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
    <div class="stay text-gray-400 pl-2 pt-2 text-sm">Last Name:</div>
    <div class="stay">
      <input
        class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
        type="text"
        id="lastName"
        value=""
        
      />
    </div>
  </div>
  </div>
   
  <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Phone Number:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="number"
          value=""
          required
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Role:</div>
      <div class="stay">
        <select class="stay w-full pl-2 py-2 rounded-md bg-white focus:outline-none" id="qualification" >
          <option value="No Role"> Select Role </option>
          <option value="Gauge Reader"> Gauge Reader </option>
          <option value="Observer"> Observer </option>
          <option value="Operational Manager"> Operational Manager </option>
          <option value="CTO"> CTO </option>
          </select>
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Type of phone:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="typeOfPhone"
          value=""
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Role in community:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="roleInCommunity"
          value=""
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Date of Birth:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="dateOfBirth"
          value=""
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Gender:</div>
      <div class="stay">
        <select
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
          id="gender"
          
        />
        <option value=""> Select gender </option>
        <option value="Male"> Male </option>
        <option value="Female"> Female </option>
        </select>
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">District:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="district"
          value=""
          
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Town/Village:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="town"
          value=""
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Station Name:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="stationName"
          value=""
        />
      </div>
    </div>
    </div>
  
    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Station:</div>
      <div class="stay">
        <select
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md bg-white"
          id="stationID"
          
        />
        <option value=""> Select Station </option>
        ${displayStationOptions(stations)}
        </select>
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Time to travel to station:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="timeToTravel"
          value=""
          
        />
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">River Basin:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="riverBasin"
          value=""
          
        />
      </div>
    </div>
    </div>

    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">National ID number:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="nationalIDNumber"
          value=""
          
        />
      </div>
    </div>
    </div>


    <div class="lg:w-1/2 w-full px-2 stay">
    <div class="stay bg-white border border-black mt-2 flex flex-col rounded-md">
      <div class="stay text-gray-400 pl-2 pt-2 text-sm">Livelihood:</div>
      <div class="stay">
        <input
          class="stay w-full pl-2 py-2 focus:outline-none rounded-md"
          type="text"
          id="livelihood"
          value=""
          
        />
      </div>
    </div>
    </div>


    <div class="stay w-3/4 bg-white border border-black mt-2 flex flex-col rounded-md hover:bg-gray-300">
      <button class="stay p-2 ">Create User</button>
    </div>
    </form>
  </div>
  </div>
      `;
    parentDiv?.appendChild(tempDiv);


    document.getElementById("closeButton")?.addEventListener('click', function handeClick(){
        removeBlackScreen()
      } )
  
    document.getElementById("createForm")?.addEventListener('submit', function handleSubmit(event){
      event.preventDefault()
      const firstName = document.getElementById("firstName") as HTMLInputElement;
      const lastName = document.getElementById("lastName") as HTMLInputElement;
      const number = document.getElementById("number") as HTMLInputElement;
      const qualification = document.getElementById("qualification") as HTMLInputElement;
      const typeOfPhone = document.getElementById("typeOfPhone") as HTMLInputElement;
      const roleInCommunity = document.getElementById("roleInCommunity") as HTMLInputElement;
      const dateOfBirth = document.getElementById("dateOfBirth") as HTMLInputElement;
      const gender = document.getElementById("gender") as HTMLInputElement;
      const district = document.getElementById("district") as HTMLInputElement;
      const town = document.getElementById("town") as HTMLInputElement;
      const stationID = document.getElementById("stationID") as HTMLInputElement;
      const stationName = document.getElementById("stationName") as HTMLInputElement;
      const timeToTravel = document.getElementById("timeToTravel") as HTMLInputElement;
      const riverBasin = document.getElementById("riverBasin") as HTMLInputElement;
      const nationalIDNumber = document.getElementById("nationalIDNumber") as HTMLInputElement;
      const livelihood = document.getElementById("livelihood") as HTMLInputElement;

      const profilePicture = document.getElementById(
        "profilePicture_viewProfile"
      );

      let argumentObject = {
        "first_name": firstName.value ,
        "last_name": lastName.value,
        "role": qualification.value,
        "telphone_number" : number.value.replace(/\s/g,''), 
        "type_of_phone": typeOfPhone.value,
        "role_in_community": roleInCommunity.value,
        "date_of_birth": dateOfBirth.value,
        "gender": gender.value,
        "station_id": stationID.value,
        "station_name": stationName.value,
        "town_or_village": town.value,
        "district": district.value,
        "river_basin": riverBasin.value,
        "time_travel_to_station": timeToTravel.value,
        "national_id_number": nationalIDNumber.value,
        "livelihood" : livelihood.value,
        "profile_picture" : " ",
      }

      createContactBE(argumentObject)
    })
  
  }
