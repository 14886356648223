import Dashboard, { displayCountries, displayCountriesDropDown, removeCountries } from "./Dashboard";
import downloadIcon from "./assets/downloadIcon.svg";
import { MapContainer, Marker, Popup, ScaleControl, TileLayer, useMap, GeoJSON } from 'react-leaflet'
import L, { LatLngExpression, geoJSON } from 'leaflet';
import * as geojson from 'geojson';
import countries from './malawi.json'
import zimbabwe from './zimbabwe.json'

import 'leaflet/dist/leaflet.css';
import { displayStations } from "./components/stations/displayStations";
import { addStation } from "./components/stations/addStation";
import { downloadCsvFromArrayOfObjects, downloadCsvFromHtmlTable, downloadStationsExcelFromArrayOfObjects } from "./downloadCSV";
import { useEffect, useState } from "react";
import MobileDashboard from "./MobileDashboard";
import { logoutMobile } from "./logoutMobile";
import profilePic from "./assets/profilePic.svg";
import { selectedCountry } from "./components/chat/misc/SelectedCountry";
export function selectedCountryMap(){
  switch (selectedCountry().toLowerCase()){
    case "malawi" : 
    return(countries.features[1].geometry as geojson.Geometry)
    case "mozambique" : 
    return(countries.features[0].geometry as geojson.Geometry)
    default:
      return ( {
        type: "Polygon",
        coordinates: [
          [
            [37.763802, -15.509234],
            [37.772617, -15.504647],
            [37.786026, -15.510471],
            [37.795952, -15.519645],
            [37.794543, -15.531283],
            [37.764945, -15.54753],
            [37.749959, -15.549717],
            [37.743098, -15.53645],
            [37.744507, -15.518697],
            [37.756286, -15.512099],
            [37.763802, -15.509234]
          ]
        ]
      } as geojson.Geometry) ;
  }
}

export function selectedCountryCenter(){
  switch (selectedCountry().toLowerCase()){
    case "malawi" : 
    return([-13.248924, 34.294467] as LatLngExpression)
    case "mozambique" : 
    return([-18.6657, 35.5296] as LatLngExpression)
    default:
      return ([-13.248924, 34.294467] as LatLngExpression) ;
  }
}

export function selectedCountryZoom(){
  switch (selectedCountry().toLowerCase()){
    case "malawi" : 
    return(6)
    case "mozambique" : 
    return(5)
    default:
      return (0) ;
  }
}

export default function Stations(stations:any) {
  const [windowSizeBolean, setSizeBolean] = useState(window.innerWidth >= 1024);
 useEffect(() => {
    window.addEventListener("resize", function handleResize() {
      setSizeBolean(this.window.innerWidth >= 1024);
    });
  }, []);

  function DisplayMarkers( stations:any){
    if (stations.length === 0) return;
    return Array.isArray(stations)
    ? stations.map((station, index) => {
      let lat = parseFloat(station.latitude.toString().replace("°", ""))
      let long = parseFloat(station.longitude.toString().replace("°", ""))
      if (isNaN(lat) || isNaN(long)) {
        return null; // skip this station
      }
      
        return (
          <Marker position={[lat, long]} icon={station.measurement_variable === "Rainfall" ? greenIcon :  icon}>
            <Popup>{"Name : " + station.station_name +" || ID : " + station.station_id}</Popup>
          </Marker>
        )
      })
    : null;
  }
  console.log(stations.stations)
  useEffect(() => {
    window.onclick = function (e) {
      let checkIfHidden = document.getElementById("countryDropdown") as HTMLElement
      if(!checkIfHidden.classList.contains("hidden")){
        
        const target = e.target as Element;
            if (!target.classList.contains("stay")) {
              removeCountries();
            }
      }
    };
  }, []);
  const headers=["Station ID", "Country", "StationName", "Variable", "District", "Region", "Basin", "Lat", "Long", "Agency", "Equipment Type", "Equipment Status", "Equipment Note"]

    const data: GeoJSON.Feature = {
        type: "Feature",
        geometry: selectedCountryMap() ,
        properties: {},
      };
  const icon = L.icon({ iconUrl: "https://i.ibb.co/QJz5Fqb/marker-icon.png",iconSize: [24,36], iconAnchor: [12,36] } );
  const greenIcon = L.icon({ iconUrl: "https://i.ibb.co/3hrDdwT/marker-icon-1.png",iconSize: [24,36], iconAnchor: [12,36] } );


  return (
    <>
          <div
        className="fixed w-full h-screen bg-black bg-opacity-50 z-10 justify-center items-center hidden stay"
        id="blackScreen"
      ></div>
      <div className="bg-gradient-to-b from-[#00A2D8] to-[#88DFBC] w-full h-screen opacity-[0.23] fixed z-0"></div>
      <div className="h-screen w-full p-1 lg:p-6 ">
        <div className="w-full min-h-full h-full relative border-2 border-white rounded-xl p-1 lg:p-6 bg-white bg-opacity-40 flex">
          {windowSizeBolean ? <Dashboard /> : <MobileDashboard />}

          <div className="w-full lg:w-9/12 xl:w-10/12 h-full min-h-full flex flex-col gap-2 lg:ml-6">
            <div className="w-full h-1/2 bg-white flex flex-col gap-7">
              <div className="flex w-full justify-between p-4 text-white font-bold h-12">
                <button className="w-[150px] lg:w-1/4 bg-[#00BB59] hover:bg-[#019448] h-12" onClick={()=>addStation()}>
                  {" "}
                  Add Station
                </button>
                <div className="w-1/2 lg:hidden flex justify-end mr-2 relative gap-4 font-normal" > 
                <div className=" stay relative text-black text-2xl"> <p className="hover:bg-gray-200 p-2 stay -rotate-90 text-center" onClick={()=>displayCountriesDropDown()}>{"<"}</p> 
              <div className=" absolute top-12 right-0 w-48 bg-white border-2 border-black flex flex-col items-start pt-4 gap-4 overflow-hidden pb-8 z-[9999] stay hidden text-sm" id="countryDropdown">
                  {displayCountries()}        
              </div>
            </div> 
                <img src={profilePic} className="w-12 h-12" onClick={()=>logoutMobile()}></img>
                </div>
                <button className=" w-24 bg-[#00BB59] hover:bg-[#019448] h-12 hidden lg:flex justify-center items-center gap-2 " onClick={()=>downloadStationsExcelFromArrayOfObjects( stations.stations, "WIS_Station_Data.xlsx")}>
                  {" "}
                  <img
                    src={downloadIcon}
                    className="w-[31px] h-[33px]"
                  ></img>{" "}
                  CSV{" "}
                </button>
              </div>
              <div className="flex-auto overflow-hidden">
                  

              <div className="h-full w-full overflow-x-auto">
                <table
                  cellSpacing="0"
                  cellPadding="0"
                  className="w-full h-full"
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          cellSpacing="0"
                          cellPadding="0"
                          className="w-full h-full table-fixed"
                        >
                          <tbody>
                            <tr className="bg-white">
                              <th className="w-40">Station ID</th>
                              <th className="w-40">Country</th>
                              <th className="w-40">Station name</th>
                              <th className="w-40">Variable</th>
                              <th className="w-40">District</th>
                              <th className="w-40">Region</th>
                              <th className="w-40">Basin</th>
                              <th className="w-40">Lat</th>
                              <th className="w-40">Long</th>
                              <th className="w-40">Agency</th>
                              <th className="w-40">Equipment Type</th>
                              <th className="w-40">Equipment Status</th>
                              <th className="w-40">Equipment Note</th>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className="h-full">
                      <td className="h-full">
                        <div className="max-h-full h-full overflow-auto scrollbar-hide">
                          <table
                            cellSpacing="0"
                            cellPadding="0"
                            className="w-full table-fixed"
                            id="stationsTable"
                          >
                            <tbody>
                              {displayStations(stations.stations)}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </div>

            <div className="w-full h-1/2 mb-2">
              <MapContainer
                center={selectedCountryCenter()}
                zoom={selectedCountryZoom()}
                scrollWheelZoom={true}
                className=" w-full h-full z-0 "
                id="map"
              >
                <GeoJSON data={data}></GeoJSON>
                {DisplayMarkers(stations.stations)}
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
