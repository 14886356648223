
import { parse } from 'node-html-parser';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

type StationData = {
  station_id: string;
  date: string;
  time: string;
  reported_data: string;
};


function convertTableToCsv(table:any, headers:any) {
    const rows = table.querySelectorAll('tr');
    const data = [];
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const cols = row.querySelectorAll('th, td');
      const rowData = [];
      for (let j = 0; j < cols.length; j++) {
        rowData.push(cols[j].innerText.trim());
      }
      data.push(rowData);
    }

    data.unshift(headers);

    return data.map(row => row.map((field: string) => `"${field.replace(/"/g, '""')}"`).join(',')).join('\n');
  }
  
 export function downloadCsvFromHtmlTable(tableId:string, headers:any) {
    try {
      const html = document.documentElement.outerHTML;
      const root = parse(html);
      const table = root.querySelector(`#${tableId}`);
      const csv = convertTableToCsv(table,headers);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'table.csv');
    } catch (error) {
      console.error(error);
    }
  }



export function downloadCsvFromArrayOfObjects(objects: any[], filename: string) {
  try {
    const headers = Object.keys(objects[0]).filter(header => !['profile_picture', 'last_message', 'is_read', 'last_message_time'].includes(header));
    const csvRows = [headers.join(',')];

    for (const object of objects) {
      delete object.profile_picture;
      delete object.last_message;
      delete object.is_read;
      const values = headers.map((header) => object[header]);
      const csvRow = values.join(',');
      csvRows.push(csvRow);
    }

    const csv = csvRows.join('\n');
    
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  } catch (error) {
    console.error(error);
  }
}


export function downloadStationsExcelFromArrayOfObjects(objects: any[], filename: string) {
  try {
    const headers = Object.keys(objects[0]).filter(
      (header) => !['profile_picture', 'last_message', 'is_read', 'last_message_time', 'readers', 'readers_details'].includes(header)
    );
    const workbook = XLSX.utils.book_new();
    const sheetName = 'Sheet1';
    const worksheetData = [headers];

    for (const object of objects) {
      delete object.profile_picture;
      delete object.last_message;
      delete object.is_read;
      const values = headers.map((header) => object[header]);
      worksheetData.push(values);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const excelFileContent = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(excelFileContent)], { type: 'application/octet-stream' });
    saveAs(blob, filename);
  } catch (error) {
    console.error(error);
  }
}


export function downloadCsvClimSoft(objects: StationData[], filename: string) {
  try {
    const dataByStation: { [stationId: string]: string[] } = {};
    const headers = ['Year', 'Month', 'Day', 'Value (mm)'];
    const csvRows: string[] = [];

    // Separate the data for each station id using tabs
    for (const object of objects) {
      const { station_id, date, time, reported_data } = object;
      const [year, month, day] = date.split('-');
      const value = parseFloat(reported_data);

      const newRow = [year, month, day, value].join(',');
      if (!dataByStation[station_id]) {
        dataByStation[station_id] = [];
      }
      dataByStation[station_id].push(newRow);
    }

    // Concatenate the headers and data for each station with tabs as separators
    for (const stationId in dataByStation) {
      const stationData = dataByStation[stationId];
      const stationRows = [headers.join(',')].concat(stationData);
      csvRows.push(`${stationRows}\n${stationData}`);
    }

    // Add id by header

    const csvContent = csvRows.join('\n\n'); // Use double newlines to separate stations
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  } catch (error) {
    console.error(error);
  }
}


export function downloadExcelFromArrayOfObjects(objects: any[], filename: string) {
  try {
    const headers = Object.keys(objects[0]).filter(
      (header) => !['profile_picture', 'last_message', 'is_read', 'last_message_time'].includes(header)
    );
    const workbook = XLSX.utils.book_new();
    const sheetName = 'Sheet1';
    const worksheetData = [headers];

    for (const object of objects) {
      delete object.profile_picture;
      delete object.last_message;
      delete object.is_read;
      const values = headers.map((header) => object[header]);
      worksheetData.push(values);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const excelFileContent = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(excelFileContent)], { type: 'application/octet-stream' });
    saveAs(blob, filename);
  } catch (error) {
    console.error(error);
  }
}

export function downloadExcelClimSoft(objects: any, filename: string) {
  try {
    const stationsData: { [stationId: string]: any[][] } = {};
    const headers = ['Year', 'Month', 'Day', 'Value (mm)'];
    const workbook = XLSX.utils.book_new();

    for (const object of objects) {
      const { station_id, date, reported_data } = object;
      const [year, month, day] = date.split('-');
      const value = parseFloat(reported_data);

      const newRow = [year, month, day, value, station_id];

      if (!stationsData[station_id]) {
        let headersWithStationId = [...headers, "Station Id"]
        stationsData[station_id] = [headersWithStationId];

      }

      stationsData[station_id].push(newRow);
    }

    for (const stationId in stationsData) {
      const sheetName = stationId.length > 31 ? stationId.substring(0, 31) : stationId;
      const sheetData = stationsData[stationId];
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    }

    const excelFileContent = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(excelFileContent)], { type: 'application/octet-stream' });
    saveAs(blob, filename);
  } catch (error) {
    console.error(error);
  }
}

// Utility function to convert a string to an ArrayBuffer
function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
}