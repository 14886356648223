import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { login } from "./components/Api/APIModules"
import { getCookie } from "./components/Api/Cookies"

export default function Login(){
  const navigate = useNavigate()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  function handleLogin() {
    if(getCookie("acessToken")){
      navigate("/home")
    }
  }
  useEffect(()=>{
    handleLogin()
  },[])
    return(
        <div className="w-full h-screen flex justify-center items-center bg-gradient-to-r from-blue-500 to-blue-600 ">
        <form className="flex flex-col text-xl w-full mx-4 sm:w-8/12 xl:w-1/2 h-3/5 justify-center items-center border-2 py-5 bg-white rounded-xl shadow-2xl " onSubmit={(e)=>login(e,username,password)}>
          <div className="text-6xl pb-12">Login</div>
          <div className="w-full md:w-3/5 flex flex-col justify-center px-4 md:px-0">
            <label htmlFor="username">Username</label>
            <input
              className="w-full rounded-3xl border-2 p-3 mt-2"
              id="username"
              type="username"
              placeholder="username"
              value={username}
              onChange={(e)=> setUsername(e.target.value)}
            />
          </div>
  
          <div className="w-full md:w-3/5 flex flex-col justify-center px-4 md:px-0">
            <label htmlFor="password" className="pt-6">
              Password
            </label>
            <input
              className=" w-full rounded-3xl border-2 p-3 mt-2"
              id="password"
              type="password"
              value = {password}
              placeholder="password"
              onChange={(e)=> setPassword(e.target.value)}
            />
          </div>
  
  
          <button className="w-3/5 bg-gradient-to-r from-blue-500 to-blue-600 p-3 rounded-3xl mt-4 text-white" type="submit">
            Login
          </button>
        </form>
      </div>
    )
}